import Header from "./desktop/header/header";
import Navbar from "./mobile/navbar/navbar";

const ReturnsRefunds = () => {
    return (
        <>
            <link rel="stylesheet" type="text/css" href="/style/terms.css"/>
            <div className={"header_tos_desktop"} style={{
                position: 'absolute',
                width: '93.74%',
                top: '20px',
                right: '3.14%',
                left: '3.13%',
                height: '634px'
            }}>
                <link rel="stylesheet" type="text/css" href="/Dark.css"/>
                <Header/>
            </div>
            <div className={"header_tos_mobile"}>
                <Navbar/>
            </div>

            <div className="entry-content clear" ast-blocks-layout="true" itemProp="text">


                <p><strong>Cancellation Policy</strong></p>


                <p>The Buyer cannot cancel the order once the order is placed since the order is immediately sent for
                    processing, hence the buyer shall be required to take delivery of the order/ shipment. In case of
                    cancellation by the customer or cancellation from the seller due to unforeseen circumstances, a full
                    refund will be initiated for all the prepaid orders.</p>


                <p><strong>Return &amp; Refund Policy</strong></p>


                <p>The Buyer can raise a return request in the following instances:</p>


                <p>(a) Where the order received is in a defective or damaged condition or a manufacturing defect; or</p>


                <p>(b) Where the order received is different from the description of the product on the listing page on
                    the Platform. The return request may be made by the Buyer by writing to support@swishclub.in or
                    through call to our customer support team, within the timelines as communicated via the respective
                    product listing page on the Swish Platform. All products may have a different return period; hence
                    buyer is advised to check the product listing page.</p>


                <p>The Buyer will be required to provide the reason for return along with relevant image(s) of the
                    product, invoice, and such other documents, as may be required to facilitate the return. In case of
                    dead-on arrival/ manufacturing defect cases, the Buyer shall be required to provide a certificate
                    from the brand certifying the defect in the product.</p>


                <p>Upon submission of the return request and relevant proofs, Swish’s team will verify the claim, and
                    the return request will be accepted or rejected. In case Swish’s team requires any further
                    information/ documents from the Buyer, it reserves its right to obtain the same from the Buyer.</p>


                <p>Upon submission of the returns request, Swish will facilitate the pickup of the product from the
                    Buyer at no additional cost to the Buyer. The customer shall keep the product intact and hand over
                    the product with an invoice, all accessories and parts, user manual, and warranty cards in the
                    original packaging at the time of pickup. We reserve the right to check the product for the
                    following conditions (wherever applicable) at the time of pickup:</p>


                <p>(a) IMEI/ name/ image/ brand/ serial number/ article number/ bar code should match and MRP tag should
                    be undetached and clearly visible.</p>


                <p>(b) All in-the-box accessories (like remote control, starter kits, instruction manuals, chargers,
                    headphones, etc.), freebies and combos (if any) should be present.</p>


                <p>(c) The product should be unused, unwashed, unsoiled, without any stains and with non-tampered
                    quality check seals/return tags/warranty seals (wherever applicable). Before returning a mobile/
                    laptop/ tablet,</p>


                <p>the device should be formatted and screen lock (Pin, Pattern or Fingerprint) must be disabled. iCloud
                    lock must be disabled for Apple devices.</p>


                <p>(d) The product (including SIM trays/ charging port/ headphone port, back-panel etc.) should be
                    undamaged and without any scratches, dents, tears or holes.</p>


                <p>(e) The product’s original packaging/ box should be undamaged.</p>


                <p>We may refuse to pick-up the product if the above conditions are not met. In case of our refusal,
                    your return request shall stand cancelled and rejected and we shall not be liable or responsible to
                    consider your return request.</p>


                <p>Once the returns request raised by the Buyer stands rejected / cancelled, the Buyer shall not be
                    eligible to raise any further return request. In case of any further clarification, the Buyer can
                    contact the customer support team of Swish.</p>


                <p>We will initiate the refund process once the product has been picked up and received at our/Seller’s
                    warehouse. It may take 5 to 7 working days for the refund to be credited to the original payment
                    method used while ordering</p>


                <p>Please contact us at support@swishclub.in for any queries or assistance in respect of cancellation or
                    return of the products.</p>


            </div>
        </>
    );
};


export default ReturnsRefunds;
