import { useState } from 'react';

const DemoForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleButtonClick = async () => {
    const webhookUrl = 'https://autom.swishclub.in/webhook/01de3532-83a8-4c8f-832c-62af78a9c7fc';
    
    try {
      if (!name && !email){
        console.error('Name and email are required for webhook call.');
      }
      else{
      const response = await fetch(webhookUrl, {
        method: 'POST',
        body: JSON.stringify({
          name,
          email,
          message,
        }),
      });
      console.log(JSON.stringify({
        name,
        email,
        message,
      }));
      if (response.ok) {
        console.log('Webhook call successful');

      } else {
        console.error('Webhook call failed');
      }
    }
    } catch (error) {
      console.error('Error during webhook call:', error);
    } finally {
      window.location.href = 'https://outlook.office365.com/owa/calendar/SwishClubDemo@swishclub.in/bookings/s/oUoszJmqSE-Nmmfn9rbJIA2';
    }
  };
  return (
    <div className="form2-wrapper">
            <div className="form2">
              <div className="bg1">
                <div className="rectangle3" />
              </div>
              <div className="button" onClick={handleButtonClick}>
                <img className="rectangle-icon" alt="" src="/rectangle.svg" />
                <div className="send-message">Book DEMO</div>
              </div>
              <div className="input-3">
                {/* <div className="rectangle4" /> */}
                {/* <div className="your-message"> */}
                {/* <label htmlFor="message">Your message*</label> */}
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  cols="50"
                  placeholder="Your message"
                  onChange={handleMessageChange}
                  style={{
                    height: '100%',
                    width: '99.79%',
                    top: '0',
                    right: '0',
                    bottom: '0',
                    left: '0.21%',
                    'borderRadius': '8.7px',
                    border: '1.3px solid var(--color-darkslateblue)',
                    'boxSizing': 'border-box',
                    opacity: '0.8',
                    'mixBlendMode': 'normal',
                    font: 'var(--font-lato)',
                    fontWeight: '500'
                  }}
                />
                {/* </div> */}
                {/* <img className="input-3-child" alt="" src="/group-34.svg" /> */}
              </div>
              <div className="input-2">
                {/* <div className="rectangle5" />
                <div className="rshdkabirgmailcom"></div> */}
                <div className="email">Email</div>
                <div style={{ marginBottom: '20px' }}></div>
                <input
                  type="text"
                  id="email"
                  name="email"
                  onChange={handleEmailChange}
                  style={{
                    height: '68.18%',
                    width: '99.79%',
                    left: '0.21%',
                    'borderRadius': '8.7px',
                    border: '1.3px solid var(--color-darkslateblue)',
                    'boxSizing': 'border-box',
                    opacity: '0.8',
                    'mixBlendMode': 'normal',
                    font: 'var(--font-lato)',
                    fontWeight: '500'
                  }}
                  required
                />
                
              </div>
              <div className="input-1" >
                {/* <div className="rectangle6" /> */}
                <div className="name" >Name</div>
                <div style={{ marginBottom: '20px' }}></div>
                <input
                  type="text"
                  id="name"
                  name="name"
                  onChange={handleNameChange}
                  style={{
                    height: '68.18%',
                    width: '99.79%',
                    left: '0.21%',
                    'borderRadius': '8.7px',
                    border: '1.3px solid var(--color-darkslateblue)',
                    'boxSizing': 'border-box',
                    opacity: '0.8',
                    'mixBlendMode': 'normal',
                    font: 'var(--font-lato)',
                    fontWeight: '500'
                  }}
                />
                {/* <div className="rashed-kabir"></div> */}

              </div>
            </div>
          </div>
    
  )
}

export default DemoForm;