const Footer = () => {
  return (<div className="dark-inner1">
    <div className="rectangle-parent11">
      <div className="group-child17" />
      <div className="social-button-parent">
        <div className="social-button">
          <a href="https://twitter.com/swishclubhq">
            <img className="twitter-icon" alt="" src="/Twitter.png" />
          </a>
          <a href="https://www.linkedin.com/company/swishclubhq/about/">
            <img className="google-icon" alt="" src="/linkInd.png" />
          </a>
          <a href="https://www.instagram.com/swishclubhq/?hl=en">
            <img className="facebook-icon" alt="" src="/instagram.png" />
          </a>
        </div>
        <a href={"/"}>
          <img className="swish-logo-11" alt="" src="/swish-logo-11.svg" />
        </a>
        {/* <div className="copyright-2023">
          Copyright © 2023 Swish | BenPlat EPP Technology Private Limited |
          All rights reserved
        </div> */}
        <div className="copyright-2023-container">
          <p className="copyright-2023-swish">
            <span className="copyright-2023">Copyright © 2023 Swish</span>
          </p>
          <p className="copyright-2023-swish">
            <span>
              <span className="benplat-epp-technology">{` BenPlat EPP Technology Private Limited | All rights reserved`}</span>
              {/* <a className="terms-of-service" href={"/terms"} style={{ "textDecoration": "none" }}>Terms Of Service</a> */}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div >);
}

export default Footer;