import { useState, useEffect } from 'react';

const Testimonials = ({ testimonials }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = testimonials.length;

  const handlePaginationClick = () => {
    setCurrentPage((prevPage) => (prevPage % totalPages) + 1);
  };

  useEffect(() => {
    const interval = setInterval(handlePaginationClick, 3000);

    return () => clearInterval(interval);
  }, [currentPage]);

  const currentTestimonial = testimonials[currentPage - 1];
  const nextTestimonial = testimonials[currentPage % totalPages];
  return (
    <div className="rectangle-parent7">
      <div className="rectangle2" />
      <div className="testimonials-section">
        <div className="subheading">Testimonials</div>
        <b className="heading1">
          <p className="offer-the-most-modern-holisti">What people say about Us.</p>
        </b>
        {/* <img
          className="testimonials-section-child"
          alt=""
          src="/group-61.svg"
        /> */}
        <div className="group-parent3">
          <div className="rectangle-parent8">
            <div className="group-child14" />
            {/* <div className="on-the-windows">
              {nextTestimonial.testimonial}
            </div> */}
            <div className="srithar-vembhu">{nextTestimonial.name}</div>
            <div className="ceo-of-red">{nextTestimonial.designation}</div>
            <img className="image-8-icon" alt="" src={nextTestimonial.companyLogo} />
          </div>
          <div className="rectangle-parent9">
            <div className="group-child15" />
            <div className="testimonail">
              {currentTestimonial.testimonial}
            </div>
            <img className="image-icon3" alt="" src={currentTestimonial.personImage} />
            <b className="testimonial-front-name">{currentTestimonial.name}</b>
            <div className="designation-front">{currentTestimonial.designation}</div>
            <img className="image-5-icon" alt="" src={currentTestimonial.companyLogo} />
          </div>
          {/* <img className="pagination-icon" alt="" src="/pagination.svg" /> */}
          <img
            className="pagination-icon"
            alt=""
            src="/pagination.svg"
            onClick={handlePaginationClick}
          />
        </div>
      </div>
    </div>
  );
}

export default Testimonials;