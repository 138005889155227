import { useState } from "react"

const BankOfferTermsAndConditionsMobile = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(false)
    return (
        <div>
            <link rel="stylesheet" type="text/css" href="/MobileViewTS.css" />
            <div className="mobile-view-ts" style={{ height: '100%' }}>
                <link rel="stylesheet" type="text/css" href="/mobile/navbar.css" />
                <nav className="navigation">
                    <a href="/" className="brand-name">
                        <img src="/mobile/swish-logo.svg" />
                    </a>
                </nav>
                {/* <img className="navbar-icon" alt="" src="/termsOfServiceMobile/navbar.svg" /> */}
                <div className="rectangle-parent">
                    <div className="group-child" />
                    <a href="/" className="brand-name">
                        <img className="swish-logo-1" alt="" src="/termsOfServiceMobile/swish-logo-1.svg" />
                    </a>
                    <div className="social-button-icon">
                        <a href="https://twitter.com/swishclubhq">
                            <img className="twitter-icon" alt="" src="/Twitter.png" />
                        </a>
                        <a href="https://www.linkedin.com/company/swishclubhq/about/">
                            <img className="google-icon" alt="" src="/linkInd.png" />
                        </a>
                        <a href="https://www.instagram.com/swishclubhq/?hl=en">
                            <img className="facebook-icon" alt="" src="/instagram.png" />
                        </a>
                    </div>
                    <div className="copyright-2023-container">
                        <p className="copyright-2023-swish">
                            <span className="copyright-2023">Copyright © 2023 Swish</span>
                        </p>
                        <p className="copyright-2023-swish">
                            <span> BenPlat EPP Technology Private Limited</span>
                        </p>
                        <p className="copyright-2023-swish">
                            <span>
                            {/* <a className="terms-of-service" href={"/terms"}>Terms Of Service</a> */}
                            All rights reserved
                            </span>
                        </p>
                    </div>
                </div>

                <div className="bank_offers_mob vertical_btnc"
                    style={{ overflow: "scroll", height: "550px", marginTop: "0px", marginBottom: "210px" }}>
                    <h2 className="">Bank Offers Terms And Conditions</h2>
                    <div className="bank_offer_mob vertical_btnc">
                        <p className="heading_btnc_mob">One Card: Credit card offer</p>

                        <ul className="content_btnc_mob">
                            <li>
                                <p>5% Instant Discount upto Rs1000 on Onecard Credit Cards, on full swipe transactions only.</p>
                            </li>
                            <li>
                                <p>Offer applicable on a minimum purchase of Rs. 5,000.</p>
                            </li>
                            <li>
                                <p>Offer from 1st August, 2024 to 31st August, 2024 (both days inclusive)</p>
                            </li>
                            <li>
                                <p>Offer is applicable only once per customer per eligible card</p>
                            </li>
                            <li>
                                <p>Benplat EPP Technology Pvt Ltd reserves the right to change / alter / revoke aforesaid offer without prior notice</p>
                            </li>
                            <li>
                                <p>By participating in this Offer, Customers/Cardholders agree to be bound by these terms & conditions and waive any right to claim ambiguity in these terms & conditions. Cardholders agree to release, discharge, indemnify and hold harmless the Banks, Benplat EPP Tech pvt ltd , and their respective directors, and employees from and against any loss, claims, damages or liability resulting in whole or in part, directly or indirectly, from acceptance and/or participation in the Offer.</p>
                            </li>
                        </ul>
                    </div>
                    <div className="bank_offer_mob vertical_btnc">
                        <p className="heading_btnc_mob">One Card: Credit card EMI offer</p>

                        <ul className="content_btnc_mob">
                            <li>
                                <p>5% Instant Discount upto Rs1200 on Onecard Credit Card EMI</p>
                            </li>
                            <li>
                                <p>Offer applicable on a minimum purchase of Rs. 5,000.</p>
                            </li>
                            <li>
                                <p>Offer from 1st August, 2024 to 31st August, 2024 (both days inclusive)</p>
                            </li>
                            <li>
                                <p>Offer is applicable only once per customer per eligible card</p>
                            </li>
                            <li>
                                <p>Benplat EPP Technology Pvt Ltd reserves the right to change / alter / revoke aforesaid offer without prior notice.</p>
                            </li>
                            <li>
                                <p>By participating in this Offer, Customers/Cardholders agree to be bound by these terms & conditions and waive any right to claim ambiguity in these terms & conditions. Cardholders agree to release, discharge, indemnify and hold harmless the Banks, Benplat EPP Tech pvt ltd , and their respective directors, and employees from and against any loss, claims, damages or liability resulting in whole or in part, directly or indirectly, from acceptance and/or participation in the Offer.</p>
                            </li>
                        </ul>
                    </div>
                    <div className="bank_offer_mob vertical_btnc">
                        <p className="heading_btnc_mob">Mobikwik: Get upto 10% (max. Rs 200) Cashback on Swish Club on
                            paying via MobiKwik Wallet.</p>
                        <ul>
                            <li><p className="content_btnc_mob">Get up to Rs.200 Cashback on Swish Club on paying via
                                MobiKwik Wallet.</p></li>
                            <li><p className="content_btnc_mob">Offer is valid for all users on a minimum transaction
                                value of Rs.499.</p></li>
                            <li><p className="content_btnc_mob">Offer is applicable once per user during the offer
                                period.</p></li>
                            <li><p className="content_btnc_mob">Offer is valid till 31st August '24.</p></li>
                        </ul>
                    </div>
                    <div className="bank_offer_mob vertical_btnc">
                        <p className="heading_btnc_mob">Mobikwik: Get upto Rs.750 Cashback on Swish Club on paying via
                            MobiKwik Wallet.</p>
                        <ul>
                            <li><p className="content_btnc_mob">Get up to Rs.750 Cashback on Swish Club on paying via
                                MobiKwik Wallet.</p></li>
                            <li><p className="content_btnc_mob">Offer is valid for all users on a minimum transaction
                                value of Rs.4,999.</p></li>
                            <li><p className="content_btnc_mob">Offer is applicable once per user during the offer
                                period.</p></li>
                            <li><p className="content_btnc_mob">Offer is valid till 31st August '24.</p></li>
                        </ul>
                    </div>
                    {/* <div className="bank_offer_mob vertical_btnc">
                        <p className="heading_btnc_mob">HDFC</p>
                        <p className="content_btnc_mob">Bank Offer10% off on HDFC FIRST Bank Credit Card EMI
                            Transactions,
                            up to
                            ₹2,000 on orders of ₹5,000 and above</p>
                    </div>
                    <div className="bank_offer_mob vertical_btnc">
                        <p className="heading_btnc_mob">HDFC</p>
                        <p className="content_btnc_mob">Bank Offer10% off on HDFC FIRST Bank Credit Card EMI
                            Transactions,
                            up to
                            ₹2,000 on orders of ₹5,000 and above</p>
                    </div>
                    <div className="bank_offer_mob vertical_btnc">
                        <p className="heading_btnc_mob">HDFC</p>
                        <p className="content_btnc_mob">Bank Offer10% off on HDFC FIRST Bank Credit Card EMI
                            Transactions,
                            up to
                            Transactions,
                            up to
                            ₹2,000 on orders of ₹5,000 and above
                            Transactions,
                            up to
                            ₹2,000 on orders of ₹5,000 and above
                            ₹2,000 on orders of ₹5,000 and above</p>
                    </div>
                    <div className="bank_offer_mob vertical_btnc">
                        <p className="heading_btnc_mob">HDFC</p>
                        <p className="content_btnc_mob">Bank Offer10% off on HDFC FIRST Bank Credit Card EMI
                            Transactions,
                            up to
                            ₹2,000 on orders of ₹5,000 and above</p>
                    </div> */}
                </div>


            </div>
        </div>
    );
};

export default BankOfferTermsAndConditionsMobile;
