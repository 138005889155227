import { useState, useEffect } from 'react';

const BenefitsBanner = ({ bannerData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = bannerData.length;
  const handleAutomaticChange = () => {
    setCurrentPage((prevPage) => (prevPage % totalPages) + 1);
  };

  useEffect(() => {
    const interval = setInterval(handleAutomaticChange, 3000);

    return () => clearInterval(interval);
  }, [currentPage, totalPages]);
  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const currentBanner = bannerData[currentPage - 1];
  return (
    <div >
      <div className="group-child8" style={{ background: currentBanner.backgroundColor }} />
      <img className="image-icon1" alt="" src={currentBanner.image} />

      <b className="best-online-agency">
        {currentBanner.title}
      </b>
      <b className="best-online-agency1" style={{marginTop: "15px"}}>
        {currentBanner.subTitle}
      </b>
      <div className="lorem-ipsum-dolor" style={{marginTop: "5px"}}>
        {currentBanner.text}
      </div>
      <div className="next-previous">
        <img
          className="next-previous-child"
          alt=""
          src="/mobile/group-13725.svg"
          onClick={() => {
            handleChangePage((prevPage) => {
              let newPage = (prevPage - 1 + totalPages) % totalPages;
              if (newPage == 0 ){
                newPage = totalPages;
              }
              return newPage;
            });
          }}
        />
        <img
          className="next-previous-item"
          alt=""
          src="/mobile/group-13729.svg"
          onClick={() => handleChangePage((prevPage) => (prevPage % totalPages) + 1)}
        />
      </div>
    </div>
  );
}

export default BenefitsBanner;