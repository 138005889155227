import {useEffect, useState} from 'react';
import ContactUsDesktop from './desktop/page/ContactUsDesktop';
import ContactUsMobile from './mobile/ContactUsMobile';
// const ContactUsDesktop = () => {

//     return (
//         <>
//             <div>
//                 Contact Desktop
//             </div>
//         </>
//     );
// };

const ContactUs = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    // Update state when the screen size changes
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            {
                (isMobile) ?
                    <ContactUsMobile/> :
                    <ContactUsDesktop/>
            }

        </div>
    );
};

export default ContactUs;
