import { useState, useEffect } from 'react';

const Introduction = ({ swishEmployerTags }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [timerText, setTimerText] = useState('');
  useEffect(() => {
    const timerId = setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % swishEmployerTags.length);
    }, 3000);
    return () => clearTimeout(timerId);
  }, [currentIndex, swishEmployerTags]);

  useEffect(() => {
    setTimerText(swishEmployerTags[currentIndex]?.value || '');
  }, [currentIndex, swishEmployerTags]);
  const handleButtonClick = () => {
    window.location.href = 'https://outlook.office365.com/owa/calendar/SwishClubDemo@swishclub.in/bookings/s/oUoszJmqSE-Nmmfn9rbJIA2';
  };
  return (
    <div className="hero-content-parent">
      <div className="hero-content">
        <div className="desc">
          <div className="cta" onClick={handleButtonClick}>
            {/* <div className="cta1">
              <div className="cta-child" />
              <b className="book-demo" onClick={handleButtonClick}>BOOK DEMO</b>
            </div> */}
            <div className="rectangle" />
            <button className="contact-us" style={{fontSize: "20.24px" , left: "22px", top: "17px"}}>
              Book DEMO
            </button>
          </div>
          <div className="desc1">
            <p className="offer-the-most-modern-holisti">
              <i className="offer-the-most">{`Offer the most modern, holistic & employee centric India’s first `}</i>
            {/* </p> */}
            {/* <p className="offer-the-most-modern-holisti"> */}
              <i className="total-rewards">{`“Employee Savings Platform” `}</i>
              <i className="offer-the-most">
                {" "}
                to your Employees across the entire Organisation
              </i>
            </p>
          </div>
          <img className="decore-icon" alt="" src="/decore.svg" />
          <div className="heading">
            <span style={{ display: 'inline-block', width: '630px' }}>
              {`Swish Club: Employee Retention Redefined - Unlock exclusive savings for your employees at  `}
              <span className="zero-cost">{timerText}</span>
            </span>

          </div>

        </div>
      </div>
      <div className="group-container">
        <div className="image-parent">
          <img className="image-icon" alt="" src="/image@2x.png" />
          {/* <div className="rectangle-group">
            <div className="group-item" />
            <div className="up-to-10-parent">
              <div className="up-to-10">Up to 10%</div>
              <div className="off-on-all">Off on all the products</div>
              <img
                className="apple-logo-black-1-icon"
                alt=""
                src="/apple-logo-black-1@2x.png"
              />
            </div>
          </div> */}
        </div>
        {/* <div className="features">
          <div className="rectangle-container">
            <div className="group-inner" />
            <div className="up-to-20-parent">
              <div className="up-to-20">Up to 20%</div>
              <div className="off-on-all1">Off on all the products</div>
              <img
                className="samsung-logo-1-icon"
                alt=""
                src="/samsung-logo-1@2x.png"
              />
            </div>
          </div>
          <div className="group-div">
            <div className="group-item" />
            <div className="up-to-10-group">
              <div className="up-to-101">Up to 10%</div>
              <div className="off-on-all2">Off on all the products</div>
              <img
                className="oneplus-2-1-icon"
                alt=""
                src="/oneplus2-1.svg"
              />
            </div>
          </div>
          <div className="rectangle-parent1">
            <div className="group-item" />
            <div className="off-on-all-the-products-parent">
              <div className="off-on-all3">Off on all the products</div>
              <img
                className="image-14-icon"
                alt=""
                src="/image-14@2x.png"
              />
              <div className="up-to-102">Up to 10%</div>
            </div>
          </div>
          <img className="block-2-icon" alt="" src="/block-2.svg" />
          <div className="rectangle-parent2">
            <div className="group-item" />
            <div className="up-to-5-parent">
              <div className="up-to-5">Up to 5%</div>
              <div className="off-on-all4">Off on all the products</div>
              <img
                className="image-13-icon"
                alt=""
                src="/image-13@2x.png"
              />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default Introduction