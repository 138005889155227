const ContactUs = () => {
  return (
    <div className="rectangle-parent17">
      <div className="rectangle7" />
      <div className="about-us-work-portfo-parent">
        <div className="about-us-work">
          <a href="/terms" style={{ textDecoration: "none", color: "#c1c1c1" }}>
            {`Terms & Service`}
          </a>
          <a style={{ marginRight: "15px", marginLeft: "15px" }}>
            {" | "}
          </a>
          <a href="/privacy" style={{ textDecoration: "none", color: "#c1c1c1" }}>
            {`Privacy Policy`}
          </a>
          <a style={{ marginRight: "15px", marginLeft: "15px" }}>
            {" | "}
          </a>
          <a href="/returns-and-refunds" style={{ textDecoration: "none", color: "#c1c1c1", marginLeft: "10px" }}>
            {`Returns & Refunds`}
          </a>
        </div>
        <div className="group-parent5">
          <div className="our-address-parent">
            <b className="our-address" style={{ "white-space": "nowrap", left: "15px"}}>Registered Address</b>
            <div className="pebda-parkway">
              <p className="offer-the-most-modern-holisti">
              GP1 205 Green Park Regency,42/5,
              </p>
              <p className="offer-the-most-modern-holisti">
                {" "}
                Near Amrrutha,
              </p>
              <p className="offer-the-most-modern-holisti">
                {" "}
                Carmelram, Bangalore South, KA
              </p>
            </div>
            <img className="icon" alt="" src="/icon.svg" />
          </div>
          <div className="our-address-office">
            <b className="our-address" style={{ left: "50px"}}>Office Address</b>
            <div className="pebda-parkway">
              <p className="offer-the-most-modern-holisti">
                Hustlehub H1907, 240, 19th Main Rd,
              </p>
              <p className="offer-the-most-modern-holisti">
                {" "}
                4th Sector, HSR Layout, Bengaluru, 560102
              </p>
            </div>
            <img className="icon" alt="" src="/icon.svg" />
          </div>
          <div className="contact-info-parent">
            <b className="contact-info">Contact Info</b>
            <a
              className="open-a-chat-container"
              href="tel:+918147406671"
              target="_blank"
            >
              <p className="offer-the-most-modern-holisti" style={{ "fontSize": "14px" }}>{`+91 8147406671 `}</p>
              <p className="offer-the-most-modern-holisti" style={{ "fontSize": "14px" }}>
                (Timing: Mon-Sat: 9am-8pm)
              </p>
            </a>
            <img className="icon1" alt="" src="/icon1.svg" />
          </div>
          <div className="live-support-parent">
            <b className="live-support">Email Support</b>
            <div className="live-chat-service-container">
              {/* <p className="offer-the-most-modern-holisti">
                live chat
              </p> */}
              <p className="offer-the-most-modern-holisti">
                <a
                  className="supportswishclubin1"
                  href="mailto:support@swishclub.in"
                  target="_blank"
                >
                  <span className="supportswishclubin2">
                    support@swishclub.in
                  </span>
                </a>
              </p>
            </div>
            <img className="icon2" alt="" src="/icon2.svg" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;