

const AppTermsOfServiceMobile = () => {
    return (
      <div>
        <link rel="stylesheet" type="text/css" href="/MobileViewTS.css" />
      <div className="logo-swish">
        <img src="/mobile/swish-logo.svg" />
      </div>
      <div className="mobile-view-ts">
        <b className="terms-of-service1">Terms Of Service</b>
        <div className="this-document-is-container">
          <p className="copyright-2023-swish">
            <span>
              This document is an electronic record as per the Information
              Technology Act, 2000 (hereby referred to as the “Act”) and rules
              made under it as applicable and provisions relating to electronic
              records in various statutes as amended by the Act. This electronic
              record is generated by a computer system and does not require any
              physical or digital signatures.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span>
              This document is published in accordance with the provisions of Rule
              3 (1) of The Information Technology guidelines (Intermediary
              Guidelines and Digital Media Ethics Code) Rules, 2021 that require
              publishing the rules and regulations, privacy policy and terms of
              use for access or usage of the website. The website,
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span>{` `}</span>
            <a
              className="httpswwwswishclubin"
              href="https://www.swishclub.in/"
              target="_blank"
            >
              <span>
                <span className="terms-of-service">https://www.swishclub.in</span>
              </span>
            </a>
            <span>
              {" "}
              or any other subdomain; and related mobile application(s)
              (hereinafter collectively referred to as the “Platform”) and the
              information, services, and other materials contained therein are
              provided and operated by BenPlat EPP Technology Private Limited, a
              company incorporated under the Companies Act, 2013, with its office
              at Hustlehub H1907, 4th Floor, 240, 19th Main Rd, 4th Sector, 
              HSR Layout, Bengaluru, Karnataka 560102 (hereinafter
              referred to as "Swish", “We”, “Us” or “Our” hereinafter).
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span>
              Unless otherwise provided by context, wherever the rights,
              privileges, and responsibilities of the Platform are referred to, it
              shall mean including the rights privileges and responsibilities of
              the Company.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span>
              By browsing or accessing the Platform or upon signing up for a Swish
              Club Account (defined below) or by using any Services (defined
              below), you are agreeing to be bound by the following terms or
              conditions ("Terms of Use”).
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span>
              As used in these Terms of Use, “You”, “Your”, “User” means any legal
              person (including the individual(s) authorized to act on behalf of
              such person) which has agreed to become a seller/buyer on the
              Platform by registering for an account on the Platform (“Swish Club
              Account”).
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span>
              Please review the collective set of guidelines including our Terms
              of Use, Privacy Policy, and other policies accessible on the
              Platform (hereinafter referred to as the “Terms”) that oversee the
              utilization of the Platform and its Services. We reserve the right,
              at our sole discretion, to modify, amend, add, or remove sections of
              these Terms at any time without prior written notice to You. By
              accessing, browsing, or using the Platform or Services, including
              following the posting of changes, the User agrees to accept and be
              bound by the Terms (as may be amended from time to time). It is your
              responsibility to periodically review these Terms for any updates or
              changes.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span>
              Please read the terms carefully before proceeding, and if you do not
              agree to all of these terms, please do not access or use the
              platform or the services.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span>
              Additional terms and conditions may apply to You concerning the
              utilization of specific services and/or specific portions or
              features of the Platform, including, but not limited to, Services,
              any other additional services offered by us from time to time,
              contests, offers, schemes, promotions, or other similar features,
              all of which terms are considered part of these Terms. You agree to
              comply with such other terms and conditions, including, where
              applicable, representing that You have the legal capacity to use or
              participate in such services or features. In case of a conflict
              between these Terms and the terms posted for or applicable to a
              specific portion of the Platform or for any specific service offered
              on or through the Platform, the latter terms shall control
              concerning your use of that portion of the Platform or the specific
              service. We expressly reserve the right at any time to add, alter,
              modify, change, or vary all or any of the terms and conditions or to
              replace wholly or in part, the contests, offers, schemes,
              promotions, etc. with another contest, offer, scheme, promotion,
              etc., or to withdraw it altogether.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span>&nbsp;</span>
          </p>
          <p className="copyright-2023-swish">
            <span>
              For ease of reference, this document is divided into the following
              sections:
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span>&nbsp;</span>
          </p>
          <p className="copyright-2023-swish">
            <b className="i-general-terms">I. General Terms; and</b>
          </p>
          <p className="copyright-2023-swish">
            <b className="i-general-terms">II. Specific Terms</b>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              The Platform is utilized by Users to meet and interact with each
              other in connection with the sale and purchase of products listed by
              third-party sellers (“Sellers”) on the Platform. The Platform is not
              and cannot be a party to or control in any manner any transaction
              between the Platform's Users.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">I. GENERAL TERMS:</span>
          </p>
          <p className="copyright-2023-swish">
            <b className="i-general-terms">
              1. APPLICATION AND ACCEPTANCE OF THE TERMS:
            </b>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (i) Your utilization of the Platform’s services, features,
              functionality, software, and products (collectively referred to as
              the “Services” hereinafter) is contingent upon acceptance of the
              Terms.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (ii) The Privacy Policy, governing the collection, use, and
              disclosure of personal information about Users, must be reviewed.
              You acknowledge the terms of the Privacy Policy and consent to the
              use of personal information about You in accordance with it.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (iii) By impliedly or expressly accepting these Terms, You also
              agree to be bound by any amendments, updates, and modifications to
              the Terms and the other policies (including but not limited to,
              Privacy Policy), as maybe amended, updated, and modified from time
              to time.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>
          <p className="copyright-2023-swish">
            <b className="i-general-terms">2. PROVISION OF SERVICES:</b>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (i) Registration on the Platform is necessary for accessing and
              using the Services. Additionally, We reserve the right, without
              prior notice, to restrict access to or use of certain Services (or
              any features within the Services) subject to other conditions that
              We may impose in Our discretion.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (ii) If You avail services while accessing the Platform, that may be
              supported and/or provided by third-party service provider(s), for
              all such services your contracting entity will be such third-party
              service provider(s), as the case may be. We disclaim all liability
              for any claims that may arise pursuant to your use of services
              provided by such third-party service provider(s).
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (iii) User agrees and confirms that any Services provided to You by
              Us are on a best-efforts basis, and We may engage services of
              third-party service provider(s) to facilitate such Services to you.
              We shall not in any manner be liable to you for failure or delay in
              providing the Services or for any temporary disablement, permanent
              discontinuance of the Services by us or for any consequences
              resulting from such actions or reasons that are beyond our
              reasonable control.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (iv) User acknowledges that the Services are being provided to You
              on an ‘as is’ and ‘as available’ basis and may be interrupted while
              browsing, transacting, using or uploading information on the
              Platform. User agrees that We reserve the right to suspend the
              Services, forthwith without assigning any reason whatsoever, at our
              sole discretion.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (v) We may at any time with or without notice, withdraw, terminate,
              and/or suspend any or part of the Services without cause or in case
              of any breach of the Terms by the User. In addition, termination of
              any or part of any Services shall not impact provision of other
              services or other business arrangements or agreements which the User
              may have entered into with Us.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>
          <p className="copyright-2023-swish">
            <b className="i-general-terms">3. ELIGIBILITY:</b>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              The Platform is available for use and access to Users, shall be a
              person, who can form legally binding contracts under Indian Contract
              Act, 1872. If You are a minor i.e., under the age of 18 years, you
              may use the Platform only with involvement of a parent or guardian.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              For the purposes of these Terms, the term ‘persons’ shall mean any
              sole proprietor, firm, company, corporation, government, state, or
              agency of a state or any association, trust, joint venture,
              consortium or partnership (whether or not having separate legal
              personality) or any other body corporate duly incorporated under the
              laws of India.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              We retain the right to deny access to anyone who We believe has
              violated any of these Terms.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>
          <p className="copyright-2023-swish">
            <b className="i-general-terms">
              4. USER ACCOUNTS AND VERIFICATION OF ACCOUNT:
            </b>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (i) To access and use the Services, You must register for a Swish
              Club Account. To complete your account registration, You must
              provide us with your full legal name, business address, phone
              number, a valid email address, and any other information as may be
              required by us for the purposes of registration on the Platform.
              Except with our approval, one User may only register one account on
              the Platform. We may cancel or terminate a user’s account if We have
              reasons to suspect that the User has concurrently registered or
              controlled two or more accounts. Further, We may reject User’s
              application, without assigning any reasons thereof, for registration
              for any other reason.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">{`(ii) A set of user ID and OTP (One Time Password) / password is unique to a single account. Any action triggered on Your Swish Club Account on the Platform or by using the unique OTP will be deemed to have been authorized by You and with Your express consent. You shall be solely responsible for maintaining the confidentiality and security of Your user ID and password and for all activities that occur under Your account. You agree that all activities that occur under Your account (including without limitation, posting any company or product information, clicking to accept any terms & conditions or rules, subscribing to or making any payment for any Services, sending emails using the Platform or other communications) will be deemed to have been authorized by You.`}</span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (iii) When you access the Platform, you are electronically
              communicating with Us. We may communicate with you by e-mail, SMS,
              WhatsApp messages or messages through other modes of communication,
              phone call or by posting notices on the Platform or by sending
              in-app notifications or any other mode of communication. For
              contractual purposes, you consent to receive communications
              (including transactional, promotional and/or commercial messages) in
              the above manner, from Us with respect to your use of the Platform
              and it shall be deemed by your continued use of the Platform that
              you agree and consent to receive any communications from Us.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>
          <p className="copyright-2023-swish">
            <b className="i-general-terms">
              5. TRANSACTIONS BETWEEN BUYER AND SELLER:
            </b>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (i) We are merely a facilitator and are not and cannot be a party to
              or control in any manner any advertisement, exhibition, making
              available, offer to sell or transactions of sale or purchase on the
              Platform.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (ii) When a product is listed for sale on the Platform by a Seller,
              products sold to Buyer by the Seller will be governed by the
              bipartite contractual arrangement entered into directly between the
              Buyer and the Seller.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (iii) All commercial/contractual terms are offered by and agreed to
              between Buyers and Sellers alone. The commercial/contractual terms
              include without limitation to price, shipping costs, payment
              methods, payment terms, date, period, and mode of delivery,
              warranties related to products and services, and after-sales
              services related to products and services. We do not have any
              control or does not determine or advise or in any way involve itself
              in the offering or acceptance of such commercial/contractual terms
              between You and the Sellers.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (iv) We do not make any representation or warranty as to specifics
              (such as quality, value, salability, etc.) of the products or
              services proposed to be sold or offered to be sold or purchased on
              the Platform. We do not implicitly or explicitly support or endorse
              the sale or purchase of any products or services on the Platform. We
              accept no liability for any errors or omissions, whether on behalf
              of it or third parties.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (v) We are not responsible for any non-performance or breach of any
              contract entered into between Buyers and Users. We cannot and do not
              guarantee that the concerned Buyers and/or Sellers will perform any
              transaction concluded on the Platform.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (vi) At no time shall We hold any right, title or interest over the
              products, nor shall We have any obligations or liabilities in
              respect of such contract entered into between Buyers and Sellers. We
              are not responsible for unsatisfactory or delayed performance of
              services or damage or delays because of products that are out of
              stock, unavailable, or backordered.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (vii) For any Services, We do not represent either the Sellers or
              the Buyer in specific transactions. We do not control and are not
              liable to or responsible for the quality, safety, suitability of
              products, lawfulness or availability of the products or services
              offered for sale on the Platform, or the ability of the Seller to
              complete a sale or the ability of Buyers to complete a purchase. We
              do not implicitly or explicitly support or endorse the sale or
              purchase of any products on the Platform.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (viii) Each User acknowledges that it is fully assuming the risks of
              conducting any sale transactions (hereinafter referred to as
              “Transaction Risk”) in connection with using the Platform or
              Services, and that it is fully assuming the risks of liability or
              harm of any kind in connection with subsequent activity of any kind
              relating to products or services that are the subject of
              transactions using the Platform. User acknowledges and undertakes
              that it is transacting on the Platform at its own risk and is using
              its best and prudent judgment before entering any transactions
              through the Platform.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (ix) We shall neither be liable nor responsible for any actions or
              inactions of the User nor any breach of conditions, representations
              or warranties of the products and hereby expressly disclaim any and
              all responsibility and liability in that regard. We shall not
              mediate or resolve any dispute or disagreement between Buyer and the
              Seller of the products or any third-party that is rendering services
              to you.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (x) In the event that any User has a dispute with any party to a
              transaction such User agrees to release and indemnify Swish Club
              (and our agents, affiliates, directors, officers and employees) from
              all claims, demands, actions, proceedings, costs, expenses and
              damages (including without limitation any actual, special,
              incidental or consequential damages) arising out of or in connection
              with such transaction. This clause shall also apply to any services
              opted for by the User by accessing any link from Our site to avail
              of any independent services related to the transaction of buy-sell
              conducted on the Platform from any third-party.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (xi) We do not make any representations pertaining to the
              information, content, products included on or delivery of the
              products or otherwise made available to User and User acknowledges
              that we are only acting as an intermediary between the Buyer and the
              Seller. User hereby further agrees, acknowledges and confirms that
              we are not responsible in any way for the products purchased by the
              Buyer from the Seller and it is explicitly agreed by the User that
              we will not in any way, under any circumstances whatsoever, be
              responsible or held liable for products purchased by Buyer from the
              Seller and/or in relation to any issue and/or dispute thereof. User
              hereby further agrees, acknowledges and confirms that under the
              aforesaid circumstances Buyer’s only.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>
          <p className="copyright-2023-swish">
            <b className="i-general-terms">6. USER(S) GENERALLY:</b>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">Contents Posted on Site</span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              You are accountable for any notes, messages, e-mails, billboard
              postings, photos, drawings, profiles, opinions, ideas, images,
              videos, audio files, details about the product published on the
              listing pages, or other materials or information posted or
              transmitted to the Platforms (collectively, "Content"). Such Content
              shall be licensed to Swish Club throughout the universe. Swish Club
              shall be entitled to, consistent with our Privacy Policy, use the
              Content or any of its elements for any type of use forever,
              including but not limited to promotional and advertising purposes
              and in any media whether now known or hereafter devised, including
              the creation of derivative works that may include Content you
              provide. You agree that any Content you post may be used by Swish
              Club, consistent with our Privacy Policy and Rules of Conduct on
              Platform as mentioned herein, and You are not entitled to any
              payment or other compensation for such use.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (i) You agree that (a) you will not copy, reproduce, download,
              re-publish, sell, distribute or resell any Services or any
              information, text, images, graphics, video clips, sound,
              directories, files, databases or listings, etc. available on or
              through the Platform (the “Platform Content”), and (b) you will not
              copy, reproduce, download, compile or otherwise use any Platform
              Content for the purposes of operating a business that competes with
              Swish Club, or otherwise commercially exploiting the Platform
              Content or systematic retrieval of Platform Content from the
              Platform to create or compile, directly or indirectly, a collection,
              compilation, database or directory (whether through robots, spiders,
              automatic devices or manual processes).
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (ii) Swish Club may review, edit, reject, refuse to post and/or
              delete any Content that in the sole judgment of Swish Club violates
              these Terms of Use or which might be offensive, illegal, or that
              might violate the rights, harm, or threaten the safety of another
              person.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (iii) Any information provided by you on this Platform shall not be
              patently false and untrue and is written or published in any form,
              with the intent to mislead or harass a person, entity, or agency for
              financial gain or to cause any injury to any person.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (iv) You may not post or transmit Content if it, in any way:
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (a) is patently offensive to the online community, such as sexually
              explicit content, or content that promotes obscenity, pedophilia,
              pornographic, bigotry, invasive of another’s privacy including
              bodily privacy, hatred or physical harm of any kind against any
              group or individual, insulting or harassing on the basis of gender,
              racially or ethnically objectionable, or otherwise inconsistent with
              or contrary to the laws in force;
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (b) harasses or advocates harassment of another person;
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (c) involves the transmission of “junk mail,” “chain letters,” or
              unsolicited mass mailing or “spamming”;
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (d) promotes illegal activities or conduct that is abusive,
              threatening, obscene, defamatory, or libelous;
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (e) infringes upon or violates any third-party's rights [(including,
              but not limited to, intellectual property rights, rights of privacy
              (including without limitation unauthorized disclosure of a person's
              name, email address, physical address, or phone number) or rights of
              publicity];
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (f) promotes an illegal or unauthorized copy of another person's
              copyrighted work (see "Copyright Complaint" below for instructions
              on how to lodge a complaint about uploaded copyrighted material),
              such as providing pirated computer programs or links to them,
              providing information to circumvent manufacture-installed
              copy-protect devices, or providing pirated music or links to pirated
              music files;
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (g) violates any law for the time being in force;
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (h) impersonates another person;
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (i) contains restricted or password-only access pages, or hidden
              pages or images (those not linked to or from another accessible
              page);
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (j) provides material that exploits people in a sexual, violent, or
              otherwise inappropriate manner or solicits personal information from
              anyone;
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (k) provides instructional information about illegal activities such
              as making or buying illegal weapons, violating someone’s privacy, or
              providing or creating computer viruses;
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (l) is harmful to a child and/or contains video, photographs, or
              images of another person aged 18 or older without his or her express
              written consent and permission or those of any minor (regardless of
              whether you have consent from the minor or his or her legal
              guardian);
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (m) tries to gain unauthorized access or exceeds the scope of
              authorized access (as defined herein) to the Platform or to
              profiles, blogs, communities, account information, bulletins, or
              other areas of the Platform or solicits passwords or personal
              identifying information for commercial or unlawful purposes from
              other users;
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (n) engages in commercial activities and/or sales without Swish
              Club’s prior written consent such as contests, sweepstakes, barter,
              advertising and pyramid schemes, or buying or selling of “virtual”
              items related to the Platform. Throughout this Terms of Use, Swish
              Club’s “prior written consent” means a communication coming from
              Swish Club’s legal department, specifically in response to your
              request, and specifically addressing the activity.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>
          <p className="copyright-2023-swish">
            <b className="i-general-terms">7. BREACHES AND SUSPENSION:</b>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (i) If any User breaches any Terms, or if We have reasonable grounds
              to believe that a User is in breach of any Terms, or could subject
              Swish or its affiliates to liability, or is otherwise found
              inappropriate or unlawful in Our opinion, We shall have the right to
              take such disciplinary actions as it deems appropriate, including
              but not limited to:
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (a) suspending or terminating the User’s account and any and all
              accounts determined to be related to such an account by Swish in its
              discretion;
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (b) blocking, restricting, downgrading, suspending, or terminating
              the subscription of, access to, or current or future use of any
              Service;
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (c) removing any product listings or other Content that the User has
              submitted, posted, or displayed;
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (d) withholding settlement of payments by Swish to the User;
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (e) any other corrective actions, discipline, or penalties as Swish
              may deem necessary or appropriate in its sole discretion.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (ii) We do not pre-screen any content or information posted,
              published, or transmitted on the Platform by the users, and We are
              under no obligation to pre-screen any such content or information.
              However, We may at our discretion and/or in accordance with
              applicable law voluntarily take down any content or information
              posted by you on the Platform. If We determine that any content or
              information is in violation of these Terms, We may remove such
              content or information from the Platform without notice. Such
              actions do not in any manner negate or dilute our position as an
              intermediary or impose any liability on Us with respect to content
              or information posted, published, or transmitted by users on the
              Platform.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (iii) Notwithstanding anything contained herein these Terms, We may,
              with or without notice and in our sole discretion, be entitled to
              suspend, reduce visibility of the product listings, de-activate, or
              de-list any product listings or User’s account for any reasons,
              including without limitation, economic constraints, operational
              difficulties, financial implications, usage behavior of the User on
              the Platform, performance of the User on the Platform, etc.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (iv) In the event a User becomes inactive or if no transaction is
              noticed by Us, in such a case We reserve our right to delist,
              deactivate or suspend a User’s account in our sole discretion, with
              or without giving any notice to the User.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (v) We reserve the right to cooperate fully with governmental
              authorities, private investigators, injured third parties in the
              investigation of any suspected criminal or civil wrongdoing and/or
              any third parties alleging a claim against you. Further, We may
              disclose the User's identity and contact information if requested by
              any third- party, government or law enforcement body, an injured
              third-party, or because of a subpoena or other legal action.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>
          <p className="copyright-2023-swish">
            <b className="i-general-terms">
              8. LIMITATION OF LIABILITY AND INDEMNITY:
            </b>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (i) To the maximum extent permitted by law, the Services provided by
              Us on or through the Platform are provided "as is", "as available"
              and “with all faults”, and We hereby expressly disclaim any and all
              warranties, express or implied, including but not limited to, any
              warranties of condition, quality, durability, performance, accuracy,
              reliability, merchantability or fitness for a particular purpose.
              All such warranties, representations, conditions, and undertakings
              are hereby excluded.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (ii) To the maximum extent permitted by law, We make no
              representations or warranties about the validity, accuracy,
              correctness, reliability, quality, stability, completeness, or
              accurateness of any information provided on or through the Platform.
              We do not represent or warrant that the manufacture, importation,
              export, distribution, offer, display, purchase, sale, and/or use of
              products or services offered or displayed on the platform does not
              violate any third-party rights. We make no representations or
              warranties of any kind concerning any products or services offered
              or displayed on the platform. Except as provided herein, to the
              fullest extent permissible by applicable law, the aggregate
              liability of Swish for any claims that may arise in connection with
              these terms shall not exceed an amount of INR 1000/-.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (iii) Under no circumstances will Swish be liable for any
              consequential, incidental, special, exemplary, or punitive damages,
              including but not limited to any lost profits that result from your
              purchase of any products on the platform or any services availed,
              even if We have been advised of the possibility of such damages.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (iv) While availing any of the payment methods offered by Swish,
              Swish is neither responsible nor takes any liability of whatsoever
              nature in respect of any loss or damage arising directly or
              indirectly to you out of the decline due to: a) lack of
              authorization for any transaction/s, b) exceeding the preset limit
              mutually agreed by You and between your "Bank/s", c) any payment
              issues arising out of the transaction, or d) decline of transaction
              for any other reason/s.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>
          <p className="copyright-2023-swish">
            <b className="i-general-terms">9. FORCE MAJEURE:</b>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              Under no circumstances, Swish shall be held liable for any default,
              losses, delay or failure or disruption of the content or in the
              performance of its or User’s obligations under this Agreement, or
              services delivered through the Platform resulting directly or
              indirectly from acts of nature, forces or causes beyond our
              reasonable control, including without limitation, internet failures,
              computer, telecommunications or any other equipment failures,
              electrical power failures, strikes, labor disputes, riots,
              insurrections, civil disturbances, shortages of labor or materials,
              fires, flood, storms, explosions, acts of God, war, governmental
              actions, epidemics, pandemics, curfews, lock-down, orders of
              domestic or foreign courts or tribunals or non- performance of third
              parties.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <b className="i-general-terms">10. INTELLECTUAL PROPERTY RIGHTS:</b>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              i. Swish is the sole owner or lawful licensee of all the rights and
              interests in the Platform and the Platform Content. All title,
              ownership and intellectual property rights in the Platform and
              Platform Content shall remain with Swish or licensors of the
              Platform Content, as the case may be. All rights not otherwise
              claimed under the Terms or by Swish are hereby reserved.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              ii. "Swish " and any other related icons and logos are registered
              trademarks of BenPlat EPP Technology Pvt Ltd (or its licensors), in
              various jurisdictions and are protected under applicable copyright,
              trademark and other proprietary rights laws. The unauthorized
              copying, modification, use or publication of these marks is strictly
              prohibited.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              iii. Swish may, at its sole discretion, permit the User(s) of the
              Platform, in writing, to use “Swish” and any other related icons and
              logos for indicative purposes in the form and manner and terms and
              conditions as maybe agreed by Swish.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              iv. All text, graphics, photographs, trademarks, logos, and artwork
              available or accessible on the Platform are third-party user
              generated content and Swish has no control over such third-party
              user generated content as Swish is merely an intermediary for the
              purposes of these Terms.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              v. You shall be solely responsible for any content or information
              posted or transmitted on the Platform and shall indemnify Swish
              against any claim or liability arising from any content or
              information posted or transmitted by You on the Platform. Any
              content or information such as but not limited to images, text,
              videos posted or transmitted on the Platform shall be licensed to
              Swish by the User uploading such content, and Swish shall have the
              worldwide, fully paid-up, perpetual and transferable license in such
              content or information for the purposes of its use on the Platform
              and for any purposes Swish deems fit. You shall not be entitled to
              any payment or compensation for any usage of the content by Swish.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>
          <p className="copyright-2023-swish">
            <b className="i-general-terms">11. NOTICES</b>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (i) All notices or demands to or upon Swish shall be made in writing
              and sent to Swish personally, by courier, or e-mail to the following
              address: Hustlehub H1907, 4th Floor, 240, 19th Main Rd, 4th Sector, 
              HSR Layout, Bengaluru, Karnataka 560102, Attn:
              Legal Department. The notices shall be effective when they are
              received by Swish in any of the above-mentioned ways.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (ii) All notices or demands to or upon a User shall be effective if
              either delivered personally, sent by courier, or registered email to
              the last-known correspondence or email address provided by the User
              to Swish or by SMS, WhatsApp messages, or in-app notifications, or
              by posting such notice or demand on an area of the Platform that is
              publicly accessible without charge or through such other mode of
              communication as Swish may deem fit in its discretion. Notice to a
              User shall be deemed to be received by such User if and when, a)
              Swish is able to demonstrate that communication, whether in physical
              or electronic form, has been sent to such User, or b) immediately
              upon Swish posting such notice on an area of the Platform that is
              accessible by the User or publicly accessible without charge.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>
          <p className="copyright-2023-swish">
            <span className="copyright-2023">12. MISCELLANEOUS PROVISIONS</span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (i) Unless otherwise communicated to you by Swish, the Terms
              constitute the entire agreement between User and Swish and govern
              the User’s use of the Platform and any of the Services. The Terms
              shall supersede any prior written or oral agreements that you may
              have had in relation to the use of the Platform and any of the
              Services.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (ii) Unless stated otherwise, We will be your one-stop solution for
              all the services on the Platform with effect from 1st November 2023
              (“Cut-Off Date”), and any new transactions on the Platform shall be
              governed by these Terms.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (iii) Swish and User are independent contractors, and no agency,
              partnership, joint venture, employee-employer relationship is
              intended or created by the Terms. The relationship between you and
              us is one of independent contractors, and nothing contained in these
              Terms will be construed to (a) give either party the power to direct
              and control the day-to-day activities of the other, (b) constitute
              the parties as partners, joint ventures, co-owners or otherwise as
              participants in a joint or common undertaking, or (c) allow you to
              create or assume any obligation on our behalf for any purpose
              whatsoever.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (iv) If any provision of the Terms is held to be invalid or
              unenforceable, such provision shall be deleted, and the remaining
              provisions shall remain valid and be enforced.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (v) Swish’s failure to enforce any right or failure to act with
              respect to any breach by User under the Terms will not constitute a
              waiver of that right nor a waiver of Swish’s right to act with
              respect to subsequent or similar breaches.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (vi) Swish shall have the right to assign the Terms (including all
              of our rights, titles, benefits, interests, and obligations and
              duties in the Terms to any person or entity (including any
              affiliates of Swish). User may not assign, in whole or part, the
              Terms to any third-party or person.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (vii) The Terms shall be governed by the laws of India, and the
              parties to the Terms agree to submit to the exclusive jurisdiction
              of the courts of Bangalore, Karnataka, India.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>
          <p className="copyright-2023-swish">
            <b className="i-general-terms">13. GRIEVANCE MECHANISM</b>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (i) The User may submit any grievance with respect to the Platform
              or the Services, including with respect to any abuse on the Platform
              and/or any discrepancies or grievances with respect to processing of
              information to the Grievance Officer at the contact details
              mentioned in 12 below. We will endeavor to resolve your grievances
              and concerns within timelines as mentioned under applicable laws.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (ii) In accordance with the IT Act, 2000, and the rules thereunder,
              the name and contact details of the grievance officer are provided
              below:
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">Mr. Dushyant Sapre</span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              Grievance Officer, BenPlat EPP Technology Pvt Ltd,
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
            Hustlehub H1907, 4th Floor, 240, 19th Main Rd, 4th Sector, 
            HSR Layout
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
            Bengaluru, Karnataka – 560102, Phone: +91 8147406671
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">E-mail: support@swishclub.in</span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">Time: Mon - Sat (9 AM - 6 PM)</span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>
          <p className="copyright-2023-swish">
            <b className="i-general-terms">14. SITE CONTROL</b>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              This site is controlled and operated by Swish, and products are sold
              by respective Sellers. All material on this site, including images,
              illustrations, audio clips, and video clips, are protected by
              copyrights, trademarks, and other intellectual property rights.
              Material on Platform is solely for Your personal, non-commercial
              use. You must not copy, reproduce, republish, upload, post, transmit
              or distribute such material in any way, including by email or other
              electronic means and whether directly or indirectly, and You must
              not assist any other person to do so. Without the prior written
              consent of the owner, modification of the materials, use of the
              materials on any other Platform or networked computer environment,
              or use of the materials for any purpose other than personal,
              non-commercial use is a violation of the copyrights, trademarks, and
              other proprietary rights, and is prohibited. Any use for which You
              receive any remuneration, whether in money or otherwise, is a
              commercial use for the purposes of this clause.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>
          <p className="copyright-2023-swish">
            <b className="i-general-terms">15. TRADEMARK INFRINGEMENT</b>
          </p>
          <p className="copyright-2023-swish">
            <span>{`Swish respects the intellectual property of others. In case You feel that Your trademark has been infringed, You can write to us at `}</span>
            <a
              className="httpswwwswishclubin"
              href="mailto:support@swishclub.in"
              target="_blank"
            >
              <span>
                <span className="terms-of-service">support@swishclub.in</span>
              </span>
            </a>
          </p>
          <p className="copyright-2023-swish">
            <span>I. SPECIFIC TERMS:</span>
          </p>
          <p className="copyright-2023-swish">
            <span>
              These Specific Terms are to be read in conjunction with the General
              Terms. In case of any conflict between the General Terms and
              Specific Terms, the provisions of Specific Terms shall supersede and
              prevail.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="copyright-2023">
              1. BUYER’S RESPONSIBILITIES, REPRESENTATIONS AND WARRANTIES
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (i) You represent, warrant, and agree that:
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              a. You are fully able and competent to understand and agree to the
              Terms.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              b. You have full power and authority to accept the Terms, to grant
              the license and authorization (if applicable), and to perform the
              obligations hereunder.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              c. Purchases on the Platform must be for end consumption only.
              Buyers must not use products purchased on the Platform for any
              commercial, promotional, resale, or further distribution purposes.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              d. You shall comply with all applicable laws while using and
              accessing the Platform.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              e. You shall be solely responsible for obtaining all necessary
              third-party licenses and permissions (if any required) regarding any
              User Content that you submit, post, or display.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              f. Any User Content that Buyer submits, posts, or displays does not
              infringe or violate any copyright, patent, trademark, trade name,
              trade secrets, or any other personal or proprietary rights of any
              third party.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (ii) Buyer represents, warrants, and agrees that:
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              a. Information and material submitted during the registration
              process or thereafter throughout the use of the Platform or any
              Services are true, accurate, current, and complete.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              b. Buyer will maintain and promptly amend all information and
              material to keep it true, accurate, current, and complete.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (iii) Buyer may be required to promptly furnish additional documents
              or information as and when requested by Swish to continue using and
              accessing the Platform and availing the Services. Buyer agrees to
              promptly provide such additional documents and information, failing
              which Swish reserves its right to take appropriate measures as set
              out under Clause 7 (Breaches and Suspension) of the General Terms.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (iv) Buyer consents to the inclusion of the contact information
              about Buyer in Swish’s database and usage of the same as per Swish’s
              privacy policy.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>
          <p className="copyright-2023-swish">
            <b className="i-general-terms">2. PAYMENTS BY BUYERS</b>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (a) Upon placing the order on the Platform, Buyer can opt to make
              payment for the Products purchased by using any of the modes of
              payment made available by us on the Platform, from time to time.
              However, it is pertinent to note and is hereby clarified that if the
              Buyer opts to make payments through any such mode made available by
              us or any third-party engaged by us for this purpose then we or the
              third-party engaged by us for the same shall only act in a fiduciary
              capacity.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (b) Buyer acknowledges that it is solely responsible for the
              transactions/payments made to the Seller for the Products purchased
              by the Buyer. Buyer acknowledges and agrees that we are acting
              merely as a payment facilitator and shall take no responsibility as
              to the legality of any payment transaction between the Seller and
              the Buyer and do not collect, process, and remit any payments in
              respect to any payment transaction on the Platform. We do not have
              any obligation, responsibility, or liability to verify any
              transactions authenticated and/or authorized by the Buyer or its
              payment instructions.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (c) The payments for the orders received from the Buyer in the
              manner as set forth under the settlement process of Seller Terms.
              You hereby agree to be bound by the applicable provisions of the
              Seller Terms in this regard (which are deemed to be incorporated
              herein by reference) and agree not to raise any dispute with respect
              to the manner of settlement by Swish.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (d) You hereby consent and agree to comply with guidelines,
              instructions, requests, etc., as maybe made by us or third-party
              banks or financial institutions, or a payment system provider from
              time to time, in relation to making payments on the Platform.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (e) Buyer hereby acknowledges and agrees that we shall not be liable
              for the failure of any transaction undertaken on the Platform for
              any reason whatsoever including but not limited to non-performance
              or omission or commission on the part of Seller, deficiency of
              service and/or Products delivered, technical errors on the Platform.
              You further acknowledge that we shall not be responsible, in any
              manner whatsoever, for any loss incurred by you for a
              failed/incomplete transaction undertaken by you on the Platform.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (f) Raising disputes against Sellers does not automatically entitle
              the Buyer to a refund or replacement for the product purchased.
              Swish shall verify the disputes so raised and may process only such
              claims that are valid and genuine. In case of an occurrence of a
              chargeback event, the refund to the Buyer will be created on
              resolution of such disputes.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (g) Buyer shall comply with all the applicable regulations/laws in
              relation to cash transactions as stipulated under the applicable tax
              laws.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (h) You, as a Buyer shall be entitled to claim a refund of the order
              price (as Your sole and exclusive remedy) as per the Return
              Shipments Policy and Undelivered Shipment Policy. Except for cash on
              delivery transactions, refund, if any, shall be made at the same
              issuing bank from where the order price was received, or through any
              other method available on the Platform, as chosen by You. For cash
              on delivery transactions, refunds, if any, will be made via
              electronic payment transfers (as per the bank account details shared
              by You).
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (i) If you avail service from any third party service provider using
              the Platform, upon receipt of instruction from such third party
              service provider, you agree and authorise us to remit and settle
              such collected payments (after deduction of our fees (if any) and
              any other charges or taxes applicable under tax laws) to the
              designated bank account of the third party service provider within
              such timelines as mentioned in the settlement process of Seller
              Terms. You agree and acknowledge that no separate authorisation will
              be required by us to collect and transfer payment to such
              third-party service provider.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (j) As required by applicable law, if the Buyer makes a purchase of
              an amount equal to or above INR 2,00,000/- (or such other limit
              specified by the applicable law), the Buyer will be required to
              upload a scanned copy of his/her PAN card on the Platform, within
              four (4) days of making the purchase, failing which, the purchase
              made by the Buyer may be cancelled by Swish. The requirement to
              submit the PAN card arises only once and if it has been submitted
              already by the Buyer, it need not be submitted again. The order of
              the Buyer shall stand cancelled if there is a discrepancy between
              the name of the Buyer and the name on the PAN Card.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (k) You are expected to treat all delivery partners of Swish (or its
              third-party logistics partner (LSP)) with courtesy and respect.
              Without prejudice to any rights under the applicable laws, we
              reserve the right to withhold access to the Platform and otherwise
              limit Your access to the Platform at Our absolute discretion if You
              behave towards any delivery partner working with Swish / LSP in a
              manner which is discourteous, disrespectful, or abusive, or which
              otherwise may be deemed to be inappropriate or unlawful.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>
          <p className="copyright-2023-swish">
            <span className="copyright-2023">3. SERVICES:</span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">(a) Exchange Offer</span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              Swish may from time to time and at its sole discretion, facilitate
              exchange offers, made available by third party exchange partners to
              enable the Buyers to exchange their pre-owned products against their
              purchases on the Platform, on the terms and conditions communicated
              to You. You agree to abide by such other terms and conditions and
              such additional terms shall be read as a part of these Terms.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (b) Swish may provide other additional services, from time to time,
              on such terms and conditions as may be communicated from time to
              time.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>
          <p className="copyright-2023-swish">
            <b className="i-general-terms">4. UNDELIVERED SHIPMENT:</b>
          </p>
          <p className="copyright-2023-swish">
            <span>{`You acknowledge that a delivery failure may occur for various reasons, you agree to the terms defined here, please reach out to us at `}</span>
            <a
              className="httpswwwswishclubin"
              href="mailto:support@swishclub.in"
              target="_blank"
            >
              <span>
                <span className="terms-of-service">support@swishclub.in</span>
              </span>
            </a>
            <span> for any issues.</span>
          </p>
          <p className="copyright-2023-swish">
            <span>&nbsp;</span>
          </p>
          <p className="copyright-2023-swish">
            <b className="i-general-terms">5. RETURNS:</b>
          </p>
          <p className="copyright-2023-swish">
            <span>
              {`In case of any return request, you agree to the terms defined here, please reach out to us at `}
            </span>
            <a
              className="httpswwwswishclubin"
              href="mailto:support@swishclub.in"
              target="_blank"
            >
              <span>
                <span className="terms-of-service">support@swishclub.in</span>
              </span>
            </a>
            <span> for any issues.</span>
          </p>
          <p className="copyright-2023-swish">
            <span>&nbsp;</span>
          </p>
          <p className="copyright-2023-swish">
            <b className="i-general-terms">6. FEES AND CHARGES:</b>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              Swish may charge a nominal fee for browsing and making purchases
              using the Platform. Swish reserves the right to change its fee from
              time to time. Swish may at its sole discretion introduce new
              services/fees and modify some or all the existing services/fees
              offered on the Platform. In such an event, Swish reserves the right
              to introduce fees for the new services offered or amend/introduce
              fees for existing/new services, as the case may be. Changes to the
              fee shall be posted on the Platform and such changes shall
              automatically become effective immediately after they are posted on
              the Platform. Unless otherwise stated, all fees shall be quoted in
              Indian Rupees. You shall be solely responsible for compliance with
              all applicable laws including those in India for making payments to
              Swish.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>
          <p className="copyright-2023-swish">
            <b className="i-general-terms">
              7. LIMITATION OF LIABILITY AND INDEMNITY:
            </b>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              Swish shall not have any liability whatsoever for any claims arising
              from:
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (a) any of your acts or omissions;
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (b) compliance with the instructions given by you or any person
              acting on your behalf;
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (c) an act or order of any government authority;
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (d) the insufficiency of the packing or labeling of Shipment;
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (e) the nature, description, or contents of the Shipment;
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">(f) any force majeure event;</span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (g) any cause which we could not avoid and the consequences whereof
              we could not prevent by the exercise of reasonable diligence; and/or
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (h) any dispute or claims between the Seller and the Buyer including
              without limitation relating to the Shipment or Products hereunder.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms"> </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms"> </span>
          </p>
        </div>
      </div>
      </div>
    );
  };
  
  export default AppTermsOfServiceMobile;