const MainHeader = () => {
    const handleButtonClick = () => {
        window.location.href = 'https://outlook.office365.com/owa/calendar/SwishClubDemo@swishclub.in/bookings/s/oUoszJmqSE-Nmmfn9rbJIA2';
    };
    return (
        <div className="group-parent1">
            <div className="group-parent2">
                <div className="rectangle-parent3" onClick={handleButtonClick} >
                    <div className="rectangle" />
                    <button className="contact-us" >
                        Book DEMO
                    </button>
                </div>
                <div className="home-about-us">
                    <a href={"/"} className={"header-links"}>Home </a>
                    <a href={"/about"} className={"header-links"}>About us </a>
                    <a href={"/contact"} className={"header-links"}>Contact us </a>
                </div>
                <div className="rectangle-parent4">
                    <div className="rectangle-signin" />
                    <b className="contact-us1">SIGN IN</b>
                </div>
            </div>
            <a href={"/"}>
                <img className="swish-logo-1" alt="" src="/swish-logo-1.svg" />
            </a>
        </div>
    )
}

export default MainHeader;