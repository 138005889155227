const JoinSwishRevolution = ({ swishEvolutionTagLine }) => {
    const handleButtonClick = () => {
        window.location.href = 'https://outlook.office365.com/owa/calendar/SwishClubDemo@swishclub.in/bookings/s/oUoszJmqSE-Nmmfn9rbJIA2';
    };
    return (
        <div className="rectangle-parent12">
            <img className="rectangle-icon1" alt="" src="/Rectangle12.png" />
            <div className="wer-dynamic-team-of-wrapper">
                <b className="wer-dynamic-team-container">
                    <p className="offer-the-most-modern-holisti" style={{fontWeight: "400"}}>{swishEvolutionTagLine.tagLine}</p>
                    <p className="offer-the-most-modern-holisti">{` “My Employer has gone above and beyond for me.” `}</p>
                </b>
            </div>
            <div className="wer-dynamic-team-of-parent">
                {/* <div className="wer-dynamic-team">Join the Swish Revolution  </div> */}
                <div className="cta-jsr" onClick={handleButtonClick}>
                    {/* <div className="cta3" onClick={handleButtonClick}>
                        <div className="cta-item" />
                        <b className="book-demo1">Book DEMO</b>
                    </div> */}
                    <div className="rectangle" />
                    <button className="contact-us" style={{fontSize: "20.24px", left: "22px", top: "17px"}}>
                        Book DEMO
                    </button>
                </div>
            </div>
        </div>
    );
}

export default JoinSwishRevolution;