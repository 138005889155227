import { useState, useEffect } from 'react';

const Heading = ({ swishEmployerTags }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [timerText, setTimerText] = useState('');
    useEffect(() => {
        const timerId = setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % swishEmployerTags.length);
        }, 3000);
        return () => clearTimeout(timerId);
    }, [currentIndex, swishEmployerTags]);

    useEffect(() => {
        setTimerText(swishEmployerTags[currentIndex]?.value || '');
    }, [currentIndex, swishEmployerTags]);
    return (
        <b className="heading">
            <span style={{ display: 'inline-block', width: '355px', marginRight: '10px' }}>
                {`Swish Club: Employee Retention Redefined - Unlock exclusive savings for your employees at `}
                <span className="zero-cost">{timerText}</span>
            </span>

        </b>
    );
}

export default Heading;