import Navbar from "./navbar/navbar";

const AccountDeletionPolicyMobile = () => {
  return (
    <div>
      <link rel="stylesheet" type="text/css" href="/MobileAccountDeletionPolicy.css" />
      <div className="mobile-view-ts">
        <Navbar />
        {/* <img className="navbar-icon" alt="" src="/termsOfServiceMobile/navbar.svg" /> */}
        <div className="rectangle-parent">
          <div className="group-child" />
          <a href="/" className="brand-name">
            <img className="swish-logo-1" alt="" src="/termsOfServiceMobile/swish-logo-1.svg" />
          </a>
          <img className="social-button-icon" alt="" src="/termsOfServiceMobile/social-button.svg" />
          <div className="copyright-2023-container">
            <p className="copyright-2023-swish">
              <span className="copyright-2023">Copyright © 2023 Swish</span>
            </p>
            <p className="copyright-2023-swish">
              <span> BenPlat EPP Technology Private Limited</span>
            </p>
            <p className="copyright-2023-swish">
              <span>
                <span className="terms-of-service">Terms Of Service</span> | All
                rights reserved
              </span>
            </p>
          </div>
        </div>
        <b className="terms-of-service1">Swish Club Account Deletion Policy</b>
        <div className="this-document-is-container">
          <p className="copyright-2023-swish">
            <span>
              Swish Club is committed to maintaining the highest standards of privacy and
              data security for all users of our platform. This Account Deletion Policy
              articulates the procedures and requirements for users seeking to delete
              their account from the Swish Club application. This policy is designed
              to safeguard user information while ensuring compliance with applicable
              data protection regulations.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>


          <p className="copyright-2023-swish">
            <b className="i-general-terms">Introduction</b>
          </p>

          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              The Swish Club app facilitates a unique ecosystem, enabling employers
              to offer personalized employee stores featuring a curated selection
              of sellers and brands. In recognizing the critical nature of data privacy,
              we provide a structured process for account deletion, ensuring that all
              user data is handled with the utmost care and security.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>
          <p className="copyright-2023-swish">
          <b className="i-general-terms" style={{ fontSize: "14px"}}>Account Deletion Procedure</b>
            {/* <span className="i-general-terms">Account Deletion Procedure</span> */}
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>

          <p className="copyright-2023-swish">
            <b className="i-general-terms">
              To uphold the integrity of our user’s data privacy, the following procedural steps are mandated for account deletion:
            </b>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>
          <p className="copyright-2023-swish">
            <b className="i-general-terms">
              1. Account Deletion Initiation:
            </b>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (a) Users wishing to delete their account must first initiate the process directly within the Swish Club app. This can be accomplished by accessing the account section in swish club mobile application, where an option to delete the account will be presented.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (b) Follow the on-screen instructions to commence the deletion process.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>

          <p className="copyright-2023-swish">
            <b className="i-general-terms">
              2. Human Resources Confirmation:
            </b>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (a) After initiating account deletion, users are required to secure a formal confirmation from their company's Human Resources (HR) department. This confirmation must explicitly acknowledge the user's request to delete their Swish Club account.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>

          <p className="copyright-2023-swish">
            <b className="i-general-terms">
              3. Confirmation Submission to Swish Club Support:
            </b>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              (a) The HR confirmation must then be submitted to Swish Club Support for verification. Submissions can be made through the designated support channels provided within the app or via direct email to our support team. Please include your name, associated company, and specific account details to facilitate verification.
            </span>
          </p>

          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>

          <p className="copyright-2023-swish">
            <b className="i-general-terms">
              Verification and Completion of Account Deletion</b>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>

          <p className="copyright-2023-swish">
            <b className="i-general-terms">
              Verification by Swish Club  </b>
          </p>

          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              Upon receipt of the account deletion request and accompanying HR confirmation, Swish Club’s support team will undertake a rigorous verification process. This measure is essential to prevent unauthorized requests and ensure the security of user data.            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>

          <p className="copyright-2023-swish">
            <b className="i-general-terms">
              Completion of Account Deletion   </b>
          </p>

          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              Verified requests will result in the permanent deletion of the user's account from the Swish Club platform, inclusive of all associated personal data. The process is expected to be concluded within a specified timeframe, after which a confirmation of account deletion will be communicated to the user.
            </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>

          <p className="copyright-2023-swish">
            <b className="i-general-terms">
              Data Retention Policy    </b>
          </p>

          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              Swish Club adheres to applicable legal and regulatory frameworks, necessitating the retention of certain data elements for predefined periods post-account deletion. Such data will be securely stored and isolated from operational use within the Swish Club platform.              </span>
          </p>
          <p className="copyright-2023-swish">
            <span className="i-general-terms">&nbsp;</span>
          </p>

          <p className="copyright-2023-swish">
            <b className="i-general-terms">
              Assistance and Inquiries   </b>
          </p>

          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              For assistance or inquiries related to the account deletion process or this policy, users are encouraged to contact Swish Club Support through the provided channels within the app or via our dedicated support email.              </span>
          </p>

          <p className="copyright-2023-swish">
            <span className="i-general-terms">
              This Account Deletion Policy is a testament to Swish Club’s unwavering commitment to privacy, data protection, and the sophisticated management of our user’s digital footprint. We appreciate your cooperation in adhering to this policy, ensuring a secure and compliant digital environment for all stakeholders.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default AccountDeletionPolicyMobile;