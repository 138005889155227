import { useState } from 'react';

const SwishBenefits = ({ swishBenefits }) => {
    const [hoveredElement, setHoveredElement] = useState(null);

    const handleMouseEnter = (elementId) => {
        setHoveredElement(elementId);
    };

    const handleMouseLeave = () => {
        setHoveredElement(null);
    };
    return (
        <div className="group-parent4">
            {
                swishBenefits.length > 0 && swishBenefits.map((data) => {
                    return (
                        <div key={data.id} className={data.rectangleParentClass}
                            onMouseEnter={() => handleMouseEnter(data.id)}
                            onMouseLeave={handleMouseLeave}>
                            <div className={"group-child18"} />
                            <div className="engage-reward">{data.description}</div>
                            <b className=''>
                                <p className="elevate-your-employer-container">{data.title}</p>
                            </b>
                            <div className={data.iconClassName} >
                                {/* {hoveredElement === data.id ? (
                                    <img src={data.hoverIcon} />
                                ) : (
                                    <img src={data.icon} />
                                )} */}
                                <img src={data.icon} />
                            </div>
                            {/* {isHovered ? <img className={data.iconClassName} alt="" src={data.hoverIcon} /> : <img className={data.iconClassName} alt="" src={data.icon} />} */}
                        </div>
                    )
                })
            }
        </div>
    );
}

export default SwishBenefits;