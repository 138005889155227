import DemoForm from "../demo-form/demo-form";
const ContactUsDesktop = () => {
    const handleButtonClick = () => {
        window.location.href = 'https://outlook.office365.com/owa/calendar/SwishClubDemo@swishclub.in/bookings/s/oUoszJmqSE-Nmmfn9rbJIA2';
    };
    return (
        <div>
            <link rel="stylesheet" type="text/css" href="/ContactUs.css" />
            <div className="contact-us">
                <div className="rectangle-parent">
                    <div className="group-child" />
                    <div className="group-parent">
                        <div className="group-container">
                            <div className="rectangle-group" onClick={handleButtonClick}>
                                <div className="rectangle" />
                                <b className="contact-us1">Book DEMO</b>
                            </div>
                            <div className="home-about-us">
                                <a href={"/"} className={"header-links"} style={{ "textDecoration": "none", "color": "#fff" }}>{`Home `}</a>
                                <a href={"/about"} className={"header-links"} style={{ "textDecoration": "none", "color": "#fff" }}>{`About us `}</a>
                                <a href={"/contact"} className={"header-links"} style={{ "textDecoration": "none", "color": "#fff" }}>{`Contact us `}</a>
                            </div>
                            <div className="rectangle-container">
                                <div className="rectangle1" />
                                <b className="contact-us2">SIGN IN</b>
                            </div>
                        </div>
                        <a href={"/"}>
                            <img className="swish-logo-1" alt="" src="/contactUs/swish-logo-1.svg" />
                        </a>
                    </div>
                </div>
                <div className="contact-us-inner">
                    <div className="group-div">
                        <div className="group-item" />
                        <div className="mask-group-parent">
                            <img className="mask-group-icon" alt="" src="/contactUs/mask-group.svg" />
                            <DemoForm />
                            <b className="dont-hesitate-to">
                                We're dedicated to providing you with an optimal experience.
                            </b>
                            <div className="dont-hesitate-to1">
                                To customise this experience for you, we'd love for you to answer
                                a few brief questions
                            </div>
                            <img className="layer-0-2" alt="" src="/contactUs/layer-0-2@2x.png" />
                        </div>
                    </div>
                </div>
                <div className="contact-us-child">
                    <div className="rectangle-parent1">
                        <div className="group-inner" />
                        <div className="social-button-parent">
                            <div className="social-button">
                                <a href="https://twitter.com/swishclubhq">
                                    <img className="twitter-icon" alt="" src="/Twitter.png" />
                                </a>
                                <a href="https://www.linkedin.com/company/swishclubhq/about/">
                                    <img className="google-icon" alt="" src="/linkInd.png" />
                                </a>
                                <a href="https://www.instagram.com/swishclubhq/?hl=en">
                                    <img className="facebook-icon" alt="" src="/instagram.png" />
                                </a>
                            </div>
                            {/* <img
                                className="social-button-icon"
                                alt=""
                                src="/contactUs/social-button.svg"
                            /> */}
                            <a href={"/"}>
                                <img className="swish-logo-11" alt="" src="/contactUs/swish-logo-11.svg" />
                            </a>
                            <div className="copyright-2023-container">
                                <p className="copyright-2023-swish">
                                    <span className="copyright-2023">Copyright © 2023 Swish</span>
                                </p>
                                <p className="copyright-2023-swish">
                                    <span>
                                        <span className="benplat-epp-technology">{` BenPlat EPP Technology Private Limited |`}</span>
                                        {/* <a className="terms-of-service" href={"/terms"} style={{ "textDecoration": "none" }}>Terms Of Service</a> */}
                                        <span className="benplat-epp-technology">
                                            {" "}
                                            All rights reserved
                                        </span>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="frame-div">
                    <div className="group-wrapper">
                        <div className="group-parent1">
                            <div className="our-address-registered">
                                <b className="our-address" style={{ "white-space": "nowrap", left: "30px"}}>Registered Address</b>
                                <div className="pebda-parkway">
                                    <p className="copyright-2023-swish">
                                    GP1 205 Green Park Regency,42/5,
                                    </p>
                                    <p className="copyright-2023-swish">
                                        {" "}
                                        Near Amrrutha,
                                    </p>
                                    <p className="copyright-2023-swish">
                                        {" "}
                                        Carmelram, Bangalore South, KA
                                    </p>
                                </div>
                                <img className="icon" alt="" src="/contactUs/icon.svg" />
                            </div>
                            <div className="our-address-parent">
                                <b className="our-address" style={{ "white-space": "nowrap"}}>Office Address</b>
                                <div className="pebda-parkway">
                                    <p className="copyright-2023-swish">
                                        Hustlehub H1907, 240, 19th Main Rd,
                                    </p>
                                    <p className="copyright-2023-swish">
                                        {" "}
                                        4th Sector, HSR Layout, Bengaluru, 560102
                                    </p>
                                </div>
                                <img className="icon" alt="" src="/contactUs/icon.svg" />
                            </div>
                            <div className="contact-info-parent">
                                <b className="contact-info">Contact Info</b>
                                <a
                                    className="open-a-chat-container"
                                    href="tel:+918147406671"
                                    target="_blank"
                                >
                                    <p className="copyright-2023-swish">{`+91 8147406671 `}</p>
                                    <p className="copyright-2023-swish" style={{ "white-space": "nowrap"}}>
                                        (Timing: Mon-Sat: 9am-8pm)
                                    </p>
                                </a>
                                <img className="icon1" alt="" src="/contactUs/icon1.svg" />
                            </div>
                            <div className="live-support-parent">
                                <b className="live-support">Live Support</b>
                                <div className="live-chat-service-container">
                                    <p className="copyright-2023-swish">live chat service</p>
                                    <p className="copyright-2023-swish">
                                        <a
                                            className="supportswishclubin1"
                                            href="mailto:support@swishclub.in"
                                            target="_blank"
                                        >
                                            <span className="supportswishclubin2">
                                                support@swishclub.in
                                            </span>
                                        </a>
                                    </p>
                                </div>
                                <img className="icon2" alt="" src="/contactUs/icon2.svg" />
                            </div>
                        </div>
                    </div>
                </div>
                <img
                    className="paid-language-instructor-1"
                    alt=""
                    src="/contactUs/paid-language-instructor-1@2x.png"
                />
                <b className="contact-us3">Contact us</b>
            </div>
        </div>
    );
};

export default ContactUsDesktop;
