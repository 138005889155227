import {useEffect, useState} from 'react';
import AboutUsDesktop from "../components/desktop/page/AboutUsDesktop";
import AboutUsMobile from "../components/mobile/AboutUsMobile";


const AboutUs = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    // Update state when the screen size changes
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            {
                (isMobile) ?
                    <AboutUsMobile/> :
                    <AboutUsDesktop/>
            }

        </div>
    );
};

export default AboutUs;
