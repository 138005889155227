import {useEffect} from "react";
import {Route, Routes, useLocation, useNavigationType,} from "react-router-dom";
import Dark from "./pages/Dark";
import AboutUs from "./components/aboutus";
import ContactUs from "./components/contactus";
import TOS from "./components/terms_of_service";
import Privacy from "./components/privacy-policy";
import ReturnsRefunds from "./components/returns_and_refunds";
import EnterpriseTerms from "./components/enterprise-terms";
import AppPrivacy from "./components/app/app-privacy-policy";
import AppTOS from "./components/app/app-terms";
import AccountDeletionPolicy from "./components/account-deletion-policy";
import BankOffersTermAndConditions from "./components/bank_offers_tnc";

function App() {
    const action = useNavigationType();
    const location = useLocation();
    const pathname = location.pathname;

    useEffect(() => {
        if (action !== "POP") {
            window.scrollTo(0, 0);
        }
    }, [action, pathname]);

    useEffect(() => {
        let title = "";
        let metaDescription = "";

        switch (pathname) {
            case "/":
                title = "";
                metaDescription = "";
                break;
        }

        if (title) {
            document.title = title;
        }

        if (metaDescription) {
            const metaDescriptionTag = document.querySelector(
                'head > meta[name="description"]'
            );
            if (metaDescriptionTag) {
                metaDescriptionTag.content = metaDescription;
            }
        }
    }, [pathname]);

    return (
        <Routes>
            <Route path="/" element={<Dark/>}/>
            <Route path="/about" element={<AboutUs/>}/>
            <Route path="/contact" element={<ContactUs/>}/>
            <Route path="/terms" element={<TOS/>}/>
            <Route path="/privacy" element={<Privacy/>}/>
            <Route path="/returns-and-refunds" element={<ReturnsRefunds/>}/>
            <Route path="/enterprise/terms-and-conditions" element={<EnterpriseTerms/>}/>
            <Route path="app/privacy" element={<AppPrivacy/>}/>
            <Route path="app/terms" element={<AppTOS/>}/>
            <Route path="/account-deletion-policy" element={<AccountDeletionPolicy/>}/>
            <Route path="/payment-offers" element={<BankOffersTermAndConditions/>}/>
        </Routes>
    );
}

export default App;
