import BenefitsBanner from "../benefits-banner/benefits-banner";
import ContactUs from "../contact-us/contact-us";
import DemoForm from "../demo-form/demo-form";
import Footer from "../footer/footer";
import MainHeader from "../header/header";
import Introduction from "../introduction/introduction";
import JoinSwishRevolution from "../join-swish/join-swish";
import MetricBlog from "../metric-blog/metric-blog";
import SwishBenefits from "../swish-benefits/swish-benefits";
import Testimonials from "../testimonials/testimonials";
import WhySwish from "../why-swish/why-swish";

import data from '../../../pages/constants';
const Desktop = () => {
    return (
        <div>
            <link rel="stylesheet" type="text/css" href="/Dark.css" />
            <div className="dark">
                <div className="rectangle-parent">
                    <div className="group-child" />
                    <div className="group-parent">
                        <MainHeader />
                        <Introduction swishEmployerTags={data.employerBenefitTags} />

                    </div>

                </div>
                < MetricBlog metricdata={data.metricData} />
                <JoinSwishRevolution swishEvolutionTagLine={data.swishTagline} />
                <b className="well-help-you">We’ll help you</b>
                <SwishBenefits swishBenefits={data.swishBenefits} />
                <BenefitsBanner bannerData={data.bannerData} />
                <WhySwish whySwish={data.whySwish} />
                <Testimonials testimonials={data.testimonials} />
                <img className="image-32-icon" alt="" src="/image-32@2x.png" />


                <div className="dark-child">
                    <div className="rectangle-parent10">
                        <div className="group-child16" />
                        <div className="mask-group-parent">
                            <img className="mask-group-icon" alt="" src="/mask-group.svg" />
                            <DemoForm />
                            <b className="dont-hesitate-to">
                                We're dedicated to providing you with an optimal experience.
                            </b>
                            <div className="dont-hesitate-to1">
                                To customise this experience for you, we'd love for you to answer
                                a few brief questions
                            </div>
                            <img className="layer-0-2" alt="" src="/layer-0-2@2x.png" />
                        </div>
                    </div>
                </div>
                <ContactUs />
                <Footer />
            </div>
        </div>
    );

}

export default Desktop;