

const AboutUsDesktop = () => {
  const handleOnJoinSwishRevolutionButtonClick = () => {
    window.location.href = 'https://outlook.office365.com/owa/calendar/SwishClubDemo@swishclub.in/bookings/s/oUoszJmqSE-Nmmfn9rbJIA2';
  };
  return (
    <div>
      <link rel="stylesheet" type="text/css" href="/AboutUs.css" />
      <div className="about-us">
        <div className="rectangle-parent">
          <div className="group-child" />
          <div className="group-parent">
            <div className="group-container">
              <div className="rectangle-group" onClick={handleOnJoinSwishRevolutionButtonClick}>
                <div className="rectangle" />
                <b className="contact-us" >Book DEMO</b>
              </div>
              <div className="home-about-us">
                <a href={"/"} className={"header-links"}>Home </a>
                <a href={"/about"} className={"header-links"}>About us </a>
                <a href={"/contact"} className={"header-links"}>Contact us </a>
              </div>
              <div className="rectangle-container">
                <div className="rectangle1" />
                <b className="contact-us1">SIGN IN</b>
              </div>
            </div>
            <a href={"/"}>
              <img className="swish-logo-1" alt="" src="/aboutUs/swish-logo-1.svg" />
            </a>
          </div>
        </div>
        <div className="about-us-inner">
          <div className="group-div">
            <div className="group-item" />
            <div className="social-button-parent">
              {/* <img
                className="social-button-icon"
                alt=""
                src="/aboutUs/social-button.svg"
              /> */}
              <div className="social-button">
                <a href="https://twitter.com/swishclubhq">
                  <img className="twitter-icon" alt="" src="/Twitter.png" />
                </a>
                <a href="https://www.linkedin.com/company/swishclubhq/about/">
                  <img className="google-icon" alt="" src="/linkInd.png" />
                </a>
                <a href="https://www.instagram.com/swishclubhq/?hl=en">
                  <img className="facebook-icon" alt="" src="/instagram.png" />
                </a>
              </div>
              <a href={"/"}>
                <img className="swish-logo-11" alt="" src="/aboutUs/swish-logo-11.svg" />
              </a>
              <div className="copyright-2023-container">
                <p className="copyright-2023-swish">
                  <span className="copyright-2023">Copyright © 2023 Swish</span>
                </p>
                <p className="copyright-2023-swish">
                  <span>
                    <span className="benplat-epp-technology">{` BenPlat EPP Technology Private Limited |`}</span>
                    {/* <a className="terms-of-service" href={"/terms"} style={{ "textDecoration": "none" }}>Terms Of Service</a> */}
                    <span className="benplat-epp-technology">
                      {" "}
                      All rights reserved
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <img className="banner-icon" alt="" src="/aboutUs/banner.svg" />
        <div className="about-us1">
          <div className="text">
            <div className="consectetur-adipisci">
              Swish Club, India's first ESP platform,
              welcomes you to elevate your lifestyle like never before.
              As an invite-only platform, we offer a world of exclusive benefits and unparalleled deals,
              where goods and services are offered at prices you can't simply Google. With privileged access granted by your employer,
              our EPP platform believes that employees deserve more.
              It's time to punch above your weight and unlock a universe of savings and perks like never before!
            </div>
          </div>
          <b className="wer-dynamic-team">
            Swish: India's first Employee Savings Platform
          </b>
        </div>
        <div className="rectangle-parent1">
          <img className="rectangle-icon" alt="" src="/aboutUs/rectangle.svg" />
          <div className="wer-dynamic-team-container">
            <span className="thinking-about-a">{` Thinking about a purchase? `}</span>
            <span className="go-ahead-swish">Go ahead, Swish it. </span>
          </div>
        </div>
        <div className="rectangle-parent2">
          <img className="rectangle-icon1" alt="" src="/aboutUs/rectangle1.svg" />
          <div className="wer-dynamic-team-of-wrapper">
            <div className="wer-dynamic-team1">What Sets Us Apart?  </div>
          </div>
          <div className="consectetur-adipisci1">
            Through Swish invite only platform, employees can access a wide range
            of products and services at preferential rates, thanks to our
            negotiated deals with suppliers. This translates to substantial
            savings that benefit both your employees and your organization. We
            believe that when employees can secure quality products and services
            at a lower cost, it boosts their overall job satisfaction.
          </div>
        </div>
        <img className="mask-group-icon" alt="" src="/aboutUs/mask-group.svg" />
        <div className="group-parent1">
          <div className="rectangle-parent3">
            <div className="group-inner" />
            <div className="mask-group-parent">
              <img className="mask-group-parent" alt="" src="/aboutUs/mask-group1.svg" />
              <div className="tailored-solutions-for-every-v-parent">
                <b className="tailored-solutions-for">{`Tailored Solutions for Every Vertical `}</b>
                <div className="swish-stands-out">
                  "Employee Savings Platform" that offers a range of exclusives for employers and employees.
                Whether your employees work in technology, manufacturing, healthcare, or any other sector, we've got them covered. 
                Our platform is carefully curated to offer products and services that are relevant to each vertical, 
                ensuring that your team can easily access what they need.  
                </div>
              </div>
            </div>
          </div>
          <div className="rectangle-parent4">
            <div className="rectangle-div" />
            <div className="mask-group-group">
              <img className="mask-group-group" alt="" src="/aboutUs/mask-group2.svg" />
              <div className="personalized-shopping-experien-parent">
                <b className="personalized-shopping-experien">
                  Personalized Shopping Experience:
                </b>
                <div className="swish-employs-state-of-the-art">
                  Swish employs state-of-the-art AI and machine learning
                  technologies to offer each employee a personalized shopping
                  journey. We understand that your team members have distinct
                  tastes and requirements, and we're committed to delivering
                  tailored product recommendations and promotions that make the
                  shopping experience both enjoyable and efficient.
                </div>
              </div>
            </div>
          </div>
          <div className="rectangle-parent5">
            <div className="group-inner" />
            <div className="mask-group-parent">
              <img className="mask-group-parent" alt="" src="/aboutUs/mask-group3.svg" />
              <div className="personalized-shopping-experien-parent">
                <b className="personalized-shopping-experien">Value driven:</b>
                <div className="swish-employs-state-of-the-art">
                  At Swish, we're dedicated to delivering value, convenience, and
                  cost-effectiveness in every transaction. We understand the
                  importance of cost efficiency in today's competitive business
                  landscape, and our platform is designed to empower employees
                  with the tools they need to make smart and budget-conscious
                  choices.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="group-parent2">
          <div className="our-history-parent">
            <div className="our-history">Our Mission</div>
            <div className="lorem-ipsum-or">
              Swish was founded with a clear mission in mind: to empower employees
              in every industry to save money across verticals like informed,
              convenient, and personalized purchases, and to help organizations
              offer a world-class benefit that drives employee satisfaction,
              engagement, and productivity.
            </div>
          </div>
          <img
            className="shutterstock-439694401-1-icon"
            alt=""
            src="/aboutUs/shutterstock-439694401-1@2x.png"
          />
        </div>
        <div className="group-parent3">
          <div className="our-history-group">
            <div className="our-history1">Why Swish?  </div>
            <div className="lorem-ipsum-or1">
              At Swish, we understand that the modern workplace is incredibly
              diverse, with employees representing a multitude of professions,
              backgrounds, and specialties. This diversity is what drives
              innovation and success in today's businesses, and we believe that
              employees deserve a platform that caters to their unique needs and
              preferences.
            </div>
          </div>
          <img
            className="hands-stacked-motivation-and-t-icon"
            alt=""
            src="/aboutUs/handsstackedmotivationandteambuildingonab20230420192859utc-2@2x.png"
          />
        </div>
        <div className="wer-dynamic-team2">
          Swish is more than just an Employee Purchase Platform; it's a powerful
          employee benefit that will set your company apart as an employer of
          choice. By partnering with us, you're not only simplifying the lives of
          your employees but also enhancing their overall job satisfaction. It's a
          win-win for your team and your business.
        </div>
        <div className="wer-dynamic-team3">
          Join us on this exciting journey to revolutionize the way employees
          across all verticals make purchases. Discover how Swish can transform
          your company's employee benefits and procurement processes, making them
          more efficient and tailored to the unique needs of your workforce. Get
          started with Swish today and experience the future of employee benefits.
        </div>
        <div style={{ width: "calc(50% - 600px)" }}>
          <div className="cta">
            <div className="cta1" onClick={handleOnJoinSwishRevolutionButtonClick}>
              <div className="cta-child" />
              <b className="join-swish-revolution" >Join Swish Revolution</b>
            </div>
          </div>
        </div>

        <img
          className="hunters-race-mybhn8kaaec-unspl-icon"
          alt=""
          src="/aboutUs/huntersracemybhn8kaaecunsplash-1@2x.png"
        />
      </div>
    </div>
  );
};

export default AboutUsDesktop;
