import { useState } from 'react';

const WhySwish = ({ whySwish }) => {
  const [hoveredElement, setHoveredElement] = useState(null);

  const handleMouseEnter = (elementId) => {
    setHoveredElement(elementId);
  };

  const handleMouseLeave = () => {
    setHoveredElement(null);
  };
  return (<div className="what-we-do">
    <div className="bg">
      <div className="mask" />
    </div>
    <div className="image-container">
      <img className="image-icon2" alt="" src="/online-win-overjoyed-young-indian-woman-holding-s-2023-11-06-09-50-59-utc%201.png" />

      <div className="component-6-parent">
        {
          whySwish.length > 0 && whySwish.map((data) => {
            return (
              <div key={data.id} className={data.rectangleParentClass}
                onMouseEnter={() => handleMouseEnter(data.id)}
                onMouseLeave={handleMouseLeave}>
                <div className="component-6-child" />
                <div className={data.descriptionClass}>
                  {data.description}
                </div>
                <b className={data.titleClassName}>
                  {data.title}
                </b>
                {/* {data.parentIconGroup ? (
                  <div className={data.parentIconGroup}>
                    <div className="group-child9" />
                    <div className={data.iconClassName}>
                      {hoveredElement === data.id ? (
                        <img src={data.hoverIcon} />
                      ) : (
                        <img src={data.icon} />
                      )}
                    </div>
                  </div>
                ) : ( */}
                  <div className='group-icon1'>
                      {/* {hoveredElement === data.id ? (
                        <img src={data.hoverIcon} />
                      ) : ( */}
                        <img src={data.icon} />
                      {/* )} */}
                    </div>
                {/* )} */}
              </div>
            )
          })
        }
      </div>
      <div className="left">
        <div className="subtitle" />
        <b className="our-included-service">
          Join the 1% Club for 100% of your Employees
        </b>
        <div className="what-we-do1">Why US</div>
      </div>
    </div>
  </div>);
}

export default WhySwish;