import Header from "./desktop/header/header";
import BankOfferTermsAndConditionsDesktop from "./desktop/page/BankOfferTermsAndConditionsDesktop";
import BankOfferTermsAndConditionsMobile from "./mobile/BankOfferTermsAndConditionsMobile";
import Navbar from "./mobile/navbar/navbar";
import {useEffect, useState} from 'react';


const BankOffersTermAndConditions = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    // Update state when the screen size changes
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            {
                (isMobile) ?
                    <BankOfferTermsAndConditionsMobile/> :
                    <BankOfferTermsAndConditionsDesktop/>
            }

        </div>
    );
};

export default BankOffersTermAndConditions;