import data from '../../pages/mobile-constants';
import BenefitsBanner from './benefits-banner/benefits-banners';
import DemoForm from './demo-form/demo-form';
import Heading from './heading/heading';
import MetricBlog from './metric-blog/metric-blog';
import Testimonials from './testimonials/testimonials';
import Navbar from "./navbar/navbar";

const MobileView = () => {
    const handleOnDemoButtonClick = () => {
        window.location.href = 'https://outlook.office365.com/owa/calendar/SwishClubDemo@swishclub.in/bookings/s/oUoszJmqSE-Nmmfn9rbJIA2';
    };
    const handleOnJoinSwishRevolutionButtonClick = () => {
        window.location.href = 'https://outlook.office365.com/owa/calendar/SwishClubDemo@swishclub.in/bookings/s/oUoszJmqSE-Nmmfn9rbJIA2';
    };
    return (<div>
        <link rel="stylesheet" type="text/css" href="/MobileView.css" />
        <div className="mobile-view">
            <Navbar />
            <div className="rectangle-parent">
                <div className="group-child" />
                <a href="/" className="brand-name">
                    <img className="swish-logo-1" alt="" src="/mobile/swish-logo-1.svg" />
                </a>
                {/* <img className="social-button-icon" alt="" src="/mobile/social-button.svg" /> */}
                <div className="social-button-icon">
                    <div className="social-button">
                        <a href='https://twitter.com/swishclubhq'>
                            <img className="twitter-icon" alt="" src="/mobile/Twitter.png" />
                        </a>
                        <a href='https://www.instagram.com/swishclubhq/?hl=en'>
                            <img className="google-icon" alt="" src="/mobile/instagram.png" />
                        </a>
                        <a href='https://www.linkedin.com/company/swishclubhq/about/'>
                            <img className="facebook-icon" alt="" src="/mobile/linkdIn.png" />
                        </a>

                    </div>
                </div>
                <div className="copyright-2023-container">
                    <p className="copyright-2023-swish">
                        <span className="copyright-2023">Copyright © 2023 Swish</span>
                    </p>
                    <p className="copyright-2023-swish">
                        <span> BenPlat EPP Technology Private Limited</span>
                    </p>
                    <p className="copyright-2023-swish">
                        <span>
                            {/* <a className="terms-of-service" href={"/terms"}>Terms Of Service</a>  */}
                            All rights reserved
                        </span>
                    </p>
                </div>
            </div>
            <div className="group-parent">
                <div className="image-parent">
                    <img className="image-icon" alt="" src="/mobile/image@2x.png" />
                    {/* <div className="rectangle-group">
                        <div className="group-item" />
                        <div className="up-to-10-parent">
                            <div className="up-to-10">Up to 10%</div>
                            <div className="off-on-all">Off on all the products</div>
                            <img
                                className="apple-logo-black-1-icon"
                                alt=""
                                src="/mobile/apple-logo-black-1@2x.png"
                            />
                        </div>
                    </div> */}
                </div>
                {/* <div className="features">
                    <div className="rectangle-container">
                        <div className="group-inner" />
                        <div className="up-to-20-parent">
                            <div className="up-to-20">Up to 20%</div>
                            <div className="off-on-all1">Off on all the products</div>
                            <img
                                className="samsung-logo-1-icon"
                                alt=""
                                src="/mobile/samsung-logo-1@2x.png"
                            />
                        </div>
                    </div>
                    <div className="group-div">
                        <div className="group-item" />
                        <div className="up-to-10-group">
                            <div className="up-to-101">Up to 10%</div>
                            <div className="off-on-all2">Off on all the products</div>
                            <img className="oneplus-2-1-icon" alt="" src="/mobile/oneplus2-1.svg" />
                        </div>
                    </div>
                    <div className="rectangle-parent1">
                        <div className="group-item" />
                        <div className="off-on-all-the-products-parent">
                            <div className="off-on-all3">Off on all the products</div>
                            <img className="image-14-icon" alt="" src="/mobile/image-14@2x.png" />
                            <div className="up-to-102">Up to 10%</div>
                        </div>
                    </div>
                    <img className="block-2-icon" alt="" src="/mobile/block-2.svg" />
                    <div className="rectangle-parent2">
                        <div className="group-item" />
                        <div className="up-to-5-parent">
                            <div className="up-to-5">Up to 5%</div>
                            <div className="off-on-all4">Off on all the products</div>
                            <img className="image-13-icon" alt="" src="/mobile/image-13@2x.png" />
                        </div>
                    </div>
                </div> */}
            </div>
            <Heading swishEmployerTags={data.employerBenefitTags} />
            <div className="desc" style={{
                left: 'calc(50 % -"172px")'
            }}>
                <p className="offer-the-most-modern-holisti">
                    <i className="offer-the-most">{`Offer the most modern, holistic & employee centric India’s first `}</i>
                    {/* </p>
                <p className="offer-the-most-modern-holisti"> */}
                    <i className="total-rewards">{`“Employee Savings Platform” `}</i>
                    <i className="offer-the-most">
                        {" "}
                        to your Employees across the entire Organisation
                    </i>
                </p>
            </div>
            <div className="cta">
                <div className="cta-child" />
                <b className="book-demo" onClick={handleOnDemoButtonClick}>BOOK DEMO</b>
            </div>
            <div className="mobile-view-child" />
            <div className="group-container">
                <MetricBlog metricdata={data.metricData} />
            </div>
            <div className="mobile-view-item" />
            <div className="cta1">
                <div className="cta-item" />
                <b className="b">Join Swish Revolution</b>
            </div>
            <div className="wer-dynamic-team-of-parent">
                <b className="wer-dynamic-team" style={{ fontWeight: "400" }}>
                    Make your employees feel
                    <p style={{ fontWeight: "700", margin: "auto" }}>
                        {` “My Employer has gone above and beyond for me.”`}
                    </p>
                </b>
                {/* <b className="wer-dynamic-team">
                JOIN THE SWISH REVOLUTION
                </b> */}
                <div className="cta2">
                    <div className="-jsr" onClick={handleOnJoinSwishRevolutionButtonClick}>
                        <div className="cta-inner" />
                        <b className="book-demo1">Book DEMO</b>
                    </div>
                </div>
                {/* <div className="wer-dynamic-team1">JOIN THE SWISH REVOLUTION  </div> */}
            </div>
            <div className="group-parent1">
                <div className="instance-parent">
                    <div className="rectangle-parent3">
                        <div className="instance-child" />
                        <div
                            className="engage-reward">{`Create a magnetic appeal to attract and retain talent effortlessly with Swish's platform.`}</div>
                        <div className="elevate-your-employer-container">
                            <p className="offer-the-most-modern-holisti-benefits">Elevate your</p>
                            <p className="offer-the-most-modern-holisti-benefits">Employer Brand</p>
                        </div>
                        <img className="group-icon" alt="" src="/mobile/elevate_your_employer_brand.png" />
                    </div>
                    <div className="rectangle-parent4">
                        <div className="group-child5" />
                        <div
                            className="engage-reward1">{`Streamline the end-to-end customer experience of managing rewards with complete automation. `}</div>
                        <b className="manage-rewards-cx">Automate your Benefits Ops</b>
                        <img className="group-icon1" alt="" src="/mobile/manage_rewards.png" />
                    </div>
                    <div className="rectangle-parent5">
                        <div className="group-child5" />
                        <div
                            className="engage-reward1">{`Fuel exceptional growth as you revolutionize your organization through an elevated talent strategy.`}</div>
                        <b className="take-your-talent-container">
                            <p className="offer-the-most-modern-holisti-benefits">Propel your talent strategy</p>
                            {/* <p className="offer-the-most-modern-holisti-benefits">
                                strategy to the next level
                            </p> */}
                        </b>
                        <img className="group-icon1" alt="" src="/mobile/talent.png" />
                    </div>
                    <div className="rectangle-parent6">
                        <div className="group-child5" />
                        <div
                            className="engage-reward1">{`Unlock personalized bliss: Offer tailor-made experiences that resonates with each individual’s preferences.`}</div>
                        <b className="take-your-talent-container">
                            <p className="offer-the-most-modern-holisti-benefits">Provide personalised Experience</p>
                        </b>
                        <img className="group-icon1" alt="" src="/mobile/personalized_experience.png" />
                    </div>
                </div>
                <b className="well-help-you">We’ll help you</b>
            </div>
            <div className="rectangle-parent7">
                <BenefitsBanner bannerData={data.bannerData} />
            </div>

            <div className="left">
                <b className="our-included-service">
                    Join the 1% Club for 100% of your Employees
                </b>
                <div className="what-we-do">Why US</div>
            </div>
            <img style={{ left: "calc(50% - 174px)" }} className="image-icon2" alt="" src="/mobile/image2@2x.png" />
            <div style={{ left: "calc(50% - 166px)" }} className="instance-group">
                <div className="rectangle-parent8">
                    <div className="instance-item" />
                    <div className="elit-esse-cillum">
                        Our D2E store brings exclusive and direct-to-employee super saving offers on the entire catalogue
                    </div>
                    <b className="d2e-stores-from-container">
                        <p className="offer-the-most-modern-holisti-benefits">{`D2E stores of Top 300 Brands `}</p>
                    </b>
                    <img className="group-icon4" alt="" src="/mobile/d2e_store.png" />
                </div>
                <div className="rectangle-parent9">
                    <div className="instance-item" />
                    <div className="elit-esse-cillum">
                        Effortless integration, get up and running in just a day along with a host of customizable features.
                    </div>
                    <b className="plug-and-play">
                        <p className="offer-the-most-modern-holisti-benefits">{`Plug and Play - go live in a day`}</p>
                    </b>
                    <div className="group">
                        <div className="group-child11" />
                        <img className="group-child12" alt="" src="/mobile/plug_play.png" />
                    </div>
                </div>
                <div className="rectangle-parent10">
                    <div className="instance-item" />
                    <div className="elit-esse-cillum">
                        Swish ensures complete data isolation, preventing unauthorized access to customer data by
                        other clients or Swish personnel.
                    </div>
                    <b className="plug-and-play">
                        <p className="offer-the-most-modern-holisti-benefits">{`100% secure, private with SOC-II`}</p>
                    </b>
                    <div className="group">
                        <div className="group-child13" />
                        <img className="group-icon5" alt="" src="/mobile/secure.png" />
                    </div>
                </div>
                <div className="rectangle-parent11">
                    <div className="instance-item" />
                    <div className="elit-esse-cillum">
                        Seamless assistance anytime, anywhere – superior real-time customer support.
                    </div>
                    <b className="plug-and-play">
                        <p className="offer-the-most-modern-holisti-benefits">{`Customer support &`}</p>
                        <p className="offer-the-most-modern-holisti-benefits">{`Live Chat `}</p>
                    </b>
                    <div className="group">
                        <div className="group-child11" />
                        <img className="group-child15" alt="" src="/mobile/customer_support.png" />
                    </div>
                </div>
            </div>
            <div className="rectangle-parent12">
                <div className="rectangle" />
                <div className="subheading">Testimonials</div>
                <b className="heading1">What people say about Us.</b>
                <Testimonials testimonials={data.testimonials} />
            </div>
            <img className="image-32-icon" alt="" src="/mobile/image-32@2x.png" />
            <div className="rectangle-parent15">
                <div className="group-child18" />
                <DemoForm />
                <b className="dont-hesitate-to">
                    We're dedicated to providing you with an optimal experience.
                </b>
                <div className="dont-hesitate-to1">
                    To customise this experience for you, we'd love for you to answer a
                    few brief questions
                </div>
                <img className="layer-0-2" alt="" src="/mobile/layer-0-2@2x.png" />
            </div>
            <div className="mobile-view-inner" />
            <div className="group-parent3">
                <div className="our-address-registered">
                    <b className="our-address" style={{left: "75px"}}>Registered Address</b>
                    <div className="pebda-parkway">
                        <p className="benplat-epp-technology">
                        GP1 205 Green Park Regency,42/5,
                        </p>
                        <p className="benplat-epp-technology">
                            {" "}
                            Near Amrrutha, Carmelram, Bangalore South, KA
                        </p>
                    </div>
                    <img className="icon" alt="" src="/icon.svg" />
                </div>
                <div className="our-address-parent">
                    <b className="our-address">Office Address</b>
                    <div className="pebda-parkway">
                        <p className="benplat-epp-technology">
                            Hustlehub H1907, 240, 19th Main Rd,
                        </p>
                        <p className="benplat-epp-technology">
                            {" "}
                            4th Sector, HSR Layout, Bengaluru, 560102
                        </p>
                    </div>
                    <img className="icon" alt="" src="/icon.svg" />
                </div>
                <div className="contact-info-parent">
                    <b className="contact-info" style={{left:"115px"}}>Contact Info</b>
                    <a
                        className="open-a-chat-container"
                        href="tel:+91814740667198"
                        target="_blank"
                    >
                        <p className="benplat-epp-technology">{`+91 8147406671 `}</p>
                        <p className="benplat-epp-technology">(Timing: Mon-Sat: 9am-8pm)</p>
                    </a>
                    <img className="icon1" alt="" src="/icon1.svg" />
                </div>
                <div className="live-support-parent">
                    <b className="live-support" style={{left: "105px"}}>Email Support</b>
                    <div className="live-chat-service-container">
                        {/* <p className="benplat-epp-technology">live chat</p> */}
                        <p className="benplat-epp-technology">
                            <a
                                className="supportswishclubin1"
                                href="mailto:support@swishclub.in"
                                target="_blank"
                            >
                                <span className="supportswishclubin2">
                                    support@swishclub.in
                                </span>
                            </a>
                        </p>
                    </div>
                    <img className="icon2" alt="" src="/icon2.svg" />
                </div>
            </div>
            <div className="about-us-work-container">
                <a href="/terms" style={{ "textDecoration": "none", color: "#C1C1C1" }}>
                    <p className="benplat-epp-technology">{`Terms & Service`}</p>
                </a>
                <a href="/privacy" style={{ "textDecoration": "none", color: "#C1C1C1" }}>
                    <p className="benplat-epp-technology">Privacy Policy</p>
                </a>
                <a href="/returns-and-refunds" style={{ "textDecoration": "none", color: "#C1C1C1" }}>
                    <p className="benplat-epp-technology">{`Returns & Refunds`}</p>
                </a>



            </div>
        </div>
    </div>);
};

export default MobileView;
