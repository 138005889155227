import Header from "./desktop/header/header";
import Navbar from "./mobile/navbar/navbar";

const EnterpriseTerms = () => {
    return (
        <div className="enterprise-div">
            <link rel="stylesheet" type="text/css" href="/style/terms.css" />
            <div className={"header_tos_desktop"} style={{
                position: 'absolute',
                width: '93.74%',
                top: '20px',
                right: '3.14%',
                left: '3.13%',
                height: '634px'
            }}>
                <link rel="stylesheet" type="text/css" href="/Dark.css" />
                <Header />
            </div>
            <div className={"header_tos_mobile"}>
                <Navbar />
            </div>

            <div className="entry-content clear" ast-blocks-layout="true" itemProp="text">


                <p><strong>ENTERPRISE TERMS AND CONDITIONS</strong></p>

                <p ><span ><span ><span
                >These Terms of Service (the &quot;Terms&quot;) are entered into by Benplat EPP Technology Private Limited (herein referred to as <strong>&ldquo;Swish Club&rdquo;</strong>) and the Partner and govern the provision of any Swish Club Service to the Partner.</span></span></span>
                </p>

                <p >&nbsp;</p>

                <ol>
                    <li ><span ><span
                    ><strong><span>DEFINITIONS:</span></strong></span></span>
                    </li>


                    {/* <p >&nbsp;</p> */}

                    {/* <ol> */}
                    {/* <li> */}
                    <ol>
                        <li ><span ><span
                        ><span>&quot;
                                <strong>Affiliate</strong>&quot; means any entity that directly or indirectly controls, is controlled by, or is under common control with the subject entity. &quot;Control,&quot; for purposes of this definition, means direct or indirect ownership or control of more than 50% of the voting interests of the subject entity.</span></span></span>
                        </li>
                        <li ><span ><span
                        ><span>&ldquo;
                                <strong>Agreement</strong>&rdquo; means this ENTERPRISE TERMS AND CONDITIONS, as amended and restated from time to time by updates.</span></span></span>
                        </li>
                        <li ><span ><span
                        ><span>&ldquo;<strong>Agreement Documents</strong>&rdquo; means the Documentation, the Order Form and applicable policies published on the Platform.</span></span></span>
                        </li>
                        <li ><span ><span
                        ><span>&ldquo;
                                <strong>Documentation</strong>&rdquo; means any additional written agreements between the Parties except for the Order Form, this Agreement, or any applicable schedules and shall include any of Swish Club usage or service guides with respect to Swish Club Services and the Policies.</span></span></span>
                        </li>
                        <li ><span ><span
                        ><span>&ldquo;
                                <strong>Employee</strong>&rdquo; means an individual who is: (i) Partner employee, consultant, contractor, who is considered as a full-time employee or contractor of Partner entity; and (ii) authorised by Partner to use Swish Club Service.</span></span></span>
                        </li>
                        <li ><span ><span
                        ><span>&ldquo;<strong>Employee Information</strong>&rdquo; means information about Partner Employees and their dependents that Swish Club collects, uses and discloses in accordance with Swish Club Policies in order to provide Swish Club Services.</span></span></span>
                        </li>
                        <li ><span ><span
                        ><span>&ldquo;
                                <strong>Fees</strong>&rdquo; shall mean subscription fees set out in the Order Form.</span></span></span>
                        </li>
                        <li><span ><span ><span
                        >&ldquo;<strong>GDPR</strong>&rdquo; means Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC;</span></span></span>
                        </li>
                        <li value="9"><span ><span
                        ><span>&ldquo;<strong>Malicious Code</strong>&rdquo; means any type of code, file, script, agent or program intended to do harm provide unauthorised access to or otherwise disrupt and/or damage computers, programs, software and/or data (including, for example, viruses, worms, time bombs, spy-ware and Trojan horses).</span></span></span>
                        </li>
                        <li value="10"><span ><span
                        ><span>&ldquo;<strong>Order Form</strong>&rdquo; means purchase order document specifying the Services to be provided hereunder that is entered into between Partner and Swish Club or any of Swish Club Affiliates, as may be amended from time to time by a Supplemental Order Form.</span></span></span>
                        </li>
                        <li><span ><span ><span
                        >&ldquo;<strong>Parties</strong>&rdquo; means both Partner and Swish Club.</span></span></span>
                        </li>
                        <li value="12"><span ><span
                        ><span>&ldquo;
                                <strong>Platform</strong>&rdquo; means the web and app portal developed by Swish Club wherein Partner and Partner Employees can access goods and services provided by third party service providers as envisioned in this Agreement.</span></span></span>
                        </li>
                        <li value="13"><span ><span
                        ><span>&ldquo;<strong>Swish Club</strong>&rdquo; means the Benplat EPP Technology Private Limited</span></span></span>
                        </li>
                        <li value="14"><span ><span
                        ><span>&ldquo;
                                <strong>Partner</strong><strong> Data</strong>&rdquo; means electronic data and information submitted by or for Partner with respect to the Services. Partner Data does not include data derived from Partner Data which has been anonymised and aggregated so that it cannot reasonably be identified or connected to an individual. </span></span></span>
                        </li>
                        <li><span ><span ><span
                        >&ldquo;<strong>Partner</strong>&rdquo; means, the legal entity subscribing to Swish Club Services as identified in the Order Form.</span></span></span>
                        </li>
                        <li><span ><span ><strong><span
                        >&ldquo;Tax&rdquo; </span></strong><span>means any tax, levy, impost, duty, or other charge or withholding of a similar nature (including any penalty or interest payable in connection with any failure to pay or any delay in paying any of the same).</span></span></span>
                        </li>
                        <li><span ><span ><strong><span
                        >&ldquo;Tax Deduction&rdquo; </span></strong><span
                        >means a deduction or withholding for or on account of Tax from any payment incurred in connection with the Services. </span></span></span>
                        </li>
                        <li><span ><span ><strong><span
                        >&ldquo;Intellectual Property Rights&rdquo; </span></strong><span
                        >means : (i) copyrights (including author&rsquo;s rights, rights in computer software and other neighbouring rights), rights in designs (including registered designs, design rights and utility models), trademarks, service marks, logos, trade or business names, brand names, domain names and URLs, rights in trade secrets, know-how and confidential and undisclosed information (such as inventions, whether patentable or not), rights in patents, database rights, semi-conductor topography rights; (ii) all registrations or applications to register, renew and/or extend any of the items referred to in (i) above; and (iii) any other rights or forms of protection of a similar nature, however designated, whether enforceable, registrable, registered or not, in any country </span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                    {/* </ol> */}
                </ol>
                <p >&nbsp;</p>

                <p >&nbsp;</p>

                <ol start="2">
                    <li><span ><span ><strong><span
                    >SERVICES </span></strong></span></span></li>
                    {/* <li> */}
                    <ol>
                        <li ><span ><span
                        ><strong><span>Fair Access:</span></strong></span></span>
                            <ol>
                                <li ><span ><span
                                ><span>Partner agrees to subscribe to Swish Club`s Platform, that can be used to connect Partner and Employees to a network of third party goods and service providers (&ldquo;
                                        <strong>Brands</strong>&rdquo;) through the web and app portal (the &ldquo;
                                        <strong>Platform</strong>&rdquo;). </span></span></span></li>
                                <li ><span ><span
                                ><span>Partner and Employees can use the Platform by registering themselves with their email id and providing certain information about themselves as prompted by the account registration form. Partner and Partner Employees will be required to consent and agree to Swish Club standard Online Usage and Privacy Agreement as may be changed from time to time (the &ldquo;
                                        <strong>Policies</strong>&rdquo;) made available at the time of signing up.</span></span></span>
                                </li>
                                <li ><span ><span
                                ><span>Swish Club will provide access to the Platform to those Employees selected by Partner in accordance with the terms of this Agreement and Agreement Documents.</span></span></span>
                                </li>
                                <li ><span ><span
                                ><span><span>Subject to the specifications in the Order Form, the Partner&#39;s subscription includes a limited number of licenses or users. The partner is authorized to use these licenses or users exclusively in accordance with the terms outlined in this Agreement and its related documents.</span></span></span></span>
                                </li>
                            </ol>
                        </li>
                    </ol>
                    {/* </li> */}
                    {/* <li> */}
                    <ol start="2">
                        <li ><span ><span
                        ><strong><span
                        >Fair Usage: </span></strong></span></span>
                            <ol>
                                <li ><span ><span
                                ><span>Neither Partner nor Employees may use the Platform and the goods and services provided therein for the purposes of reselling such goods and/or services in the same condition in which Partner or Employees had purchased it. Partner acknowledges that Brands may offer goods and/or services at a lower price on the Platform as part of Swish Club Services and any violation of this clause will be considered as a material breach of this Agreement.</span></span></span>
                                </li>
                                <li ><span ><span
                                ><span>Partner and Employees to comply with guidelines set by the Brands. </span><span
                                >The Brands may have their own terms with regard to warranties, returns, restriction on the number of products or services a user can purchase, disclaimers and other restrictions or requirements.</span></span></span>
                                </li>
                            </ol>
                        </li>
                    </ol>
                    {/* </li> */}
                    {/* <li> */}
                    <ol start="3">
                        <li ><span ><span
                        ><strong><span>Value Added Services: </span></strong></span></span>
                            <ol >
                                <li ><span ><span
                                ><span>Swish Club offers value-added services to lower the cost of access to goods and services and improve convenience by fetching data from human resource systems (personal information API) and/or payroll systems (deduction API). Employees may choose to consent and avail such services solely for the purpose of 1-click checkout, or pre-filling information or provide data verification directly to service providers. Swish Club will not fetch such data without Employee consent and will provide record of data fetching to the Partner. </span></span></span>
                                </li>
                                <li><span ><span ><span
                                >Swish Club offers digital wallet to reward Employees (&ldquo;
                                    <strong>Rewards Program</strong>&rdquo;) which can also be used by Partner for performance linked incentive grant to Partner Employees (&ldquo;
                                    <strong>Rewards Credits</strong>&rdquo;). Unless otherwise required by law, Swish Club reserves the right to change, modify and/or eliminate any rewards program (including the value of any rewards) with an advance notice of 30 days. Any credits, points or other awards pursuant to a rewards program are personal to the Employee and may not be sold, transferred or assigned for any reselling purpose. Swish Club reserves the right to &ldquo;unregister&rdquo; and make ineligible for the rewards program to any rewards program member that has been inactive for twelve (12) consecutive months or no longer continues to be an Employee beyond twelve (12) months, unless otherwise required by law. &ldquo;Inactive&rdquo; is defined as no rewards credits earned in the immediately preceding twelve (12) months. In the event that an Employee is unregistered or rendered inactive, then all accumulated Rewards Credits in such Employees account are void.</span></span></span>
                                </li>
                            </ol>
                        </li>
                    </ol>
                    {/* </li> */}
                </ol>

                <p >&nbsp;</p>

                <ol start="3">
                    <li><span ><span ><strong><span
                    >PAYMENT AND TERMS</span></strong></span></span></li>
                    {/* <li> */}
                    <ol>
                        <li><span ><span ><span
                        >Unless otherwise specified in the Order Form, Partner will pay all charges incurred in connection with the subscription of the Platform (the &quot;
                            <strong>Fees</strong>&quot;), u<span >sing a payment method approved in advance by Swish Club</span>. Partner will pay within the payment term specified in the Order Form from the invoice date. <span
                            >In case of prepayment, invoice will be issued after receiving the payment.</span></span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                    {/* <li> */}
                    <ol start="2">
                        <li><span ><span ><span
                        >If Partner is required to make a Tax Deduction, Partner shall make that Tax Deduction and any payment required in connection with that Tax Deduction within the time allowed and in the minimum amount required by law.</span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                    {/* <li> */}
                    <ol start="3">
                        <li><span ><span ><span
                        >Within thirty (30) days of making either a Tax Deduction or any payment required in connection with that Tax Deduction, Partner shall deliver to Swish Club evidence reasonably satisfactory to Swish Club that the Tax Deduction has been made or (as applicable) any appropriate payment paid to the relevant taxing authority.</span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                    {/* <li> */}
                    <ol start="4">
                        <li><span ><span ><span
                        >Partner and Swish Club shall co-operate in completing any procedural formalities necessary for Partner to obtain authorization to make that payment without a Tax Deduction. </span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                    {/* <li> */}
                    <ol start="5">
                        <li><span ><span ><span
                        >Swish Club shall be entitled to charge interest and recovery costs on overdue amounts as specified by the relevant law or as set out in the Order Form. </span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                    {/* <li> */}
                    <ol start="6">
                        <li><span ><span ><span
                        >Any claim on an invoice can only be raised within one (1) month of receipt. </span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                </ol>

                <p >&nbsp;</p>

                <ol start="4">
                    <li><span ><span ><strong><span
                    >PARTNER</span></strong><strong><span> ROLE AND RESPONSIBILITIES</span></strong></span></span>
                    </li>


                    {/* <p >&nbsp;</p> */}

                    <ol>
                        {/* <li> */}
                        <ol>
                            <li ><span ><span
                            ><span>Partner and Partner Employees must adhere to this Agreement, Agreement Documents and the policies formulated and updated by Swish Club from time to time for continued access and use of the Platform.</span></span></span>
                            </li>
                        </ol>
                        {/* </li> */}
                        {/* <li> */}
                        <ol start="2">
                            <li ><span ><span
                            ><span>Partner hereby agrees not to or permit any of Partner Employees to: (a) make Swish Club Service available to anyone other than the Employees and direct family members; (b) sell, resell, license, sublicense, distribute, make available, rent or lease Swish Club Service; (c) use Swish Club Service to store or transmit infringing, libellous, or otherwise unlawful or tortious material, or to store or transmit material in violation of third-party privacy rights; (d) use Swish Club Service to store or transmit any Malicious Code; (e) interfere with or disrupt the integrity or performance of Swish Club Service or third-party data contained therein; (f) attempt to gain unauthorised access to Swish Club Platform, or its related systems or networks; (g) permit direct or indirect access to or use of any Service in a way that circumvents a contractual usage limit, or use of any of Swish Club Services in a manner that violates this Agreement and the Agreement Documents or to access or use any of Swish Club intellectual property except as permitted under this Agreement, an Order Form, or Other Documentation; or (h) disassemble, reverse engineer, or decompile Swish Club Service or access it to build a competitive product or service or build a product or service using similar ideas, features, functions or graphics of the Platform, or copy any ideas, features, functions or graphics of Swish Club Service or&nbsp; determine whether Swish Club Services are within the scope of any patent. Any use of Swish Club Services in breach of this Agreement or the Agreement Documents, by Partner or Partner Employees that in Swish Club judgment threatens the security, integrity or availability of Swish Club Services, may result in the immediate suspension of Swish Club Services. Partner also hereby represent and warrant that Partner are not Swish Club competitor. </span></span></span>
                            </li>
                        </ol>
                        {/* </li> */}
                        {/* <li> */}
                        <ol start="3">
                            <li ><span ><span
                            ><span>Partner may not access Swish Club Services if Partner is Swish Club competitor, except with Swish Club prior written consent. In addition, Partner may not access the Services for purposes of monitoring their availability, performance, functionality, or for any other benchmarking or competitive purposes.</span></span></span>
                            </li>
                        </ol>
                        {/* </li> */}
                    </ol>
                </ol>
                <p >&nbsp;</p>

                <ol start="5">
                    <li><span ><span ><strong><span
                    >INTELLECTUAL PROPERTY RIGHTS</span></strong></span></span></li>


                    {/* <p >&nbsp;</p> */}

                    <ol>
                        <li ><span ><span
                        ><span>Each Party remains the sole owner of its own Intellectual Property Rights. </span></span></span>
                        </li>
                        <li ><span ><span
                        ><span>Swish Club is the sole owner or authorized licensee of all Intellectual Property Rights in and to the Swish Club Platform and any specific development or program developed by Swish Club for Partner&#39;s use of the Swish Club Platform, whether currently existing or in the future </span></span></span>
                        </li>
                        <li ><span ><span
                        ><span>Subject to the terms and conditions of this Agreement, during the Term, Swish Club hereby grant Partner and Partner Employees a non-exclusive, non-sublicensable, non-transferable, national license to access and use the Platform, solely for the purposes as set forth herein.</span> </span></span>
                        </li>
                        <li ><span ><span
                        ><span>Partner acknowledges that all the intellectual property rights, including copyrights, patents, trademarks, and trade secrets, in the Platform and the Services and any content relating thereto are owned by Swish Club or the Brands. Neither this Agreement (nor Partner access to the Platform or Services) transfers to Partner or any third party any rights, title or interest in or to such intellectual property rights, except for the limited access rights expressly set forth in Sectio</span><span
                        >n, 5c above. S</span><span>wish Club and Swish Club Brands reserve all rights not granted in this Agreement or the Agreement Documents. There are no implied licenses granted under this Agreement or the Agreement Documents.</span></span></span>
                        </li>
                        <li ><span ><span
                        ><span>The Parties agree that any improvements or updates to any such property discovered as part of the Services shall be, as between the Parties, the sole property of the Swish Club and shall form part of the Swish Club Intellectual Property Rights. Partner also hereby irrevocably waive and agree not to assert any moral rights, rights of integrity, rights of paternity, or similar rights to object to or prevent modification of any Swish Club Intellectual Property Rights, or to insist upon being identified as the creator or author of any Swish Club Intellectual Property Rights.</span></span></span>
                        </li>
                        <li ><span ><span
                        ><span>For the limited purposes of providing the Services herein, Partner hereby allow, grant and permit a limited, non-exclusive, royalty free, worldwide, non-transferable license to display and use Partner marks, logos and branding information on the Platform and for the purposes of allowing Swish Club to show Partner as a client of Swish Club Services.</span></span></span>
                        </li>
                    </ol>
                </ol>
                <p >&nbsp;</p>

                <ol start="6">
                    <li><span ><span ><strong><span
                    >CONFIDENTIALITY OF INFORMATION</span></strong></span></span></li>
                    {/* <li> */}
                    <ol>
                        <li ><span ><span
                        ><span>&ldquo;<strong>Confidential Information</strong>&rdquo; means all information disclosed by a party (&ldquo;
                                <strong>Disclosing Party</strong>&rdquo;) to the other party (&ldquo;<strong>Receiving Party</strong>&rdquo;), whether orally or in any tangible form, that is designated as confidential or that reasonably should be understood to be confidential given the nature of the information and the circumstances of disclosure. Partner Confidential Information includes Partner Data; Swish Club Confidential Information includes the Services; and Confidential Information of each party includes the terms and conditions of this Agreement and all Order Forms (including pricing). However, Confidential Information does not include any information that (i) is or becomes generally known to the public without breach of any obligation owed to the Disclosing Party, (ii) was known to the Receiving Party prior to its disclosure by the Disclosing Party without breach of any obligation owed to the Disclosing Party, (iii) is received from a third party without breach of any obligation owed to the Disclosing Party, or (iv) was independently developed by the Receiving Party and (v) to the extent compelled by law to do so.</span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                    {/* <li> */}
                    <ol start="2">
                        <li ><span ><span
                        ><span>The Receiving Party will use the same degree of care to protect the confidentiality and security of the Confidential Information of the Disclosing Party that it uses to protect the confidentiality and security of its own Confidential Information of like kind (but not less than reasonable care). The Receiving Party agrees to (i) not use any Confidential Information of the Disclosing Party for any purpose outside the scope of this Agreement and (ii) except as otherwise authorised by the Disclosing Party in writing, limit access to Confidential Information of the Disclosing Party to those of its and its Affiliates&rsquo; employees and contractors who need that access for purposes consistent with this Agreement and who have signed confidentiality agreements with the Receiving Party containing protections not materially less protective of the Confidential Information than those herein. Except for the purposes of this Agreement and for the Services, neither party will disclose the Confidential Information of the other party to any third party other than its Affiliates, legal counsel and accountants without the other party&rsquo;s prior written consent, provided that a party that makes any such disclosure to its Affiliate, legal counsel or accountants will remain responsible for such Affiliate&rsquo;s, legal counsel&rsquo;s or accountant&rsquo;s compliance with this &ldquo;Confidentiality&rdquo; section. Notwithstanding the foregoing: (i) Swish Club may disclose the terms of this Agreement and any applicable Order Form to a third party to the extent necessary to perform Swish Club obligations and to provide Services to Partner under this Agreement, under terms of confidentiality materially as protective as set forth herein; and (ii) a third-party hack, unauthorised access, or Malicious Code shall not be considered unauthorised disclosure of confidential information by a party for the purposes of this section.</span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                    {/* <li> */}
                    <ol start="3">
                        <li ><span ><span
                        ><span>If, in providing the Services, We shall process personal data (as that term is defined in GDPR) on Your behalf, and that processing is subject to GDPR, Our &ldquo;GDPR Processing Terms&rdquo; shall be appended to the Order Form and shall apply in relation to that processing. If there is any conflict or inconsistency between any provision of the GDPR Processing Terms and any other provision of this Agreement (or any other document incorporated into it by reference), that provision of GDPR Processing Terms shall take precedence. You hereby represent and warrant that, to the extent that We process personal data in accordance with this Agreement and that processing is subject to GDPR: (a) You have notified us that Your Data contains personal data; (b) You have taken all necessary steps (including obtaining all necessary consents and giving all necessary notices) to enable lawful processing of the personal data by Us, including the transfer of that personal data outside of the European Economic Area; and (c) Your instructions to Us with respect to the processing of such personal data are lawful.</span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                    {/* <li> */}
                    <ol start="4">
                        <li ><span ><span
                        ><span>Either Party shall promptly notify the other Party of any loss or of any unauthorized access to, use or disclosure of the other Party&rsquo;s Confidential Information, or attempt thereof, of which it becomes aware. Each Party will use its reasonable endeavors to assist the other Party in remedying any unauthorized use or disclosure of its Confidential Information. </span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                </ol>

                <p >&nbsp;</p>

                <ol start="7">
                    <li><span ><span ><strong><span
                    >TERM AND TERMINATION </span></strong></span></span></li>
                    {/* <li> */}
                    <ol>
                        <li ><span ><span
                        ><span>This Agreement shall commence on the date set out as the signing date of the Order Form and shall continue until the earlier of (a) the term end date specified in the Order Form or a renewed term end date as agreed between the Parties or (b) early determination in accordance with this Agreement and Agreement Documents. </span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                    {/* <li> */}
                    <ol start="2">
                        <li ><span ><span
                        ><span>A party may terminate this Agreement for cause (i) upon providing written notice to the other party of a material breach, if such breach remains uncured at the expiration of the 30 day period commencing upon the date upon which the notice of the breach was provided, (ii) if the other party becomes the subject of a petition in bankruptcy or any other proceeding relating to insolvency, receivership, liquidation or assignment for the benefit of creditors, or (iii) as otherwise set out in this Agreement. </span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                    {/* <li> */}
                    <ol start="3">
                        <li ><span ><span
                        ><span>Effect of Termination: </span></span></span>
                            <ol >
                                <li ><span ><span
                                ><span>In the event this Agreement is terminated by Partner as a result of an uncured material breach of this Agreement by Us, Swish Club will return the unearned subscription fees calculated on an equitable basis. Otherwise, any unpaid Fees under this Agreement and the applicable Order Form shall be due and payable for the remainder of the term. In no event will termination relieve Partner of Partner obligation to pay any Fees or Expenses payable to Swish Club for the period prior to the effective date of termination. </span></span></span>
                                </li>
                                <li ><span ><span
                                ><span>All access to the Platform by Partner and Partner Employees will cease at the end of the notice period, in the event of an uncured material breach of this Agreement. At Swish Club sole option, Swish Club may choose to either cancel and refund or facilitate any ongoing orders.</span></span></span>
                                </li>
                            </ol>
                        </li>
                    </ol>
                    {/* </li> */}
                </ol>

                <p >&nbsp;</p>

                <ol start="8">
                    <li><span ><span ><strong><span
                    >DISCLAIMER OF WARRANTIES</span></strong></span></span></li>
                    {/* <li> */}
                    <ol>
                        <li ><span ><span
                        ><span>To the maximum extent permitted by law and except as expressly set forth in the agreement, order form, or agreement documents, and any addendums thereto, partner understand and agree that the services and the platform are provided on an &ldquo;as-is&rdquo; basis are without condition, representation or warranty of any kind, whether direct, indirect, collateral, express or implied, as to any matter whatsoever, including without limitation, ownership, merchantability or fitness for any particular purpose, or error free operation (even if covered by the international sale of goods convention, and whether or not swish club know, have reason to know, have been advised, or is otherwise in fact aware of any such purpose), whether alleged to arise by law, by reason of custom, or usage in trade, or by the course of dealing.</span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                    {/* <li> */}
                    <ol start="2">
                        <li ><span ><span
                        ><span>Further, swish club are in no way responsible or liable to any party with regard to any products or services offered, provided or sold on the platform. &nbsp;</span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                </ol>

                <p >&nbsp;</p>

                <ol start="9">
                    <li><span ><span ><strong><span
                    >LIMITATION OF LIABILITY</span></strong></span></span></li>
                    {/* <li> */}
                    <ol>
                        <li ><span ><span
                        ><span>In no event shall the aggregate liability of each party together with all of its affiliates arising out of or related to this agreement exceed the total fee paid by partner in the twelve months preceding the first incident out of which the liability arose. The foregoing limitation will apply whether an action is in contract or tort and regardless of the theory of liability, but will not limit partner and partner affiliates&rsquo; obligations to pay amounts owed for fees and expenses and swish club intellectual property indemnifcation obligations set forth herein. In addition, the foregoing limitation of liability shall not apply to either party&rsquo;s breach of confidentiality, privacy or gdpr obligations for which the aggregate liability of each party together with all of its affiliates shall not exceed 150% the total fee paid by partner in the twelve months preceding the first incident out of which the liability arose.</span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                    {/* <li> */}
                    <ol start="2">
                        <li ><span ><span
                        ><span>In no event will either party or its affiliates have any liability arising out of or related to this agreement for any lost profits, revenues, goodwill, or indirect, special, incidental, consequential, cover, business interruption or punitive damages, whether an action is in contract or tort and regardless of the theory of liability, even if a party or its affiliates have been advised of the possibility of such damages or if a party&rsquo;s or its affiliates&rsquo; remedy otherwise fails of its essential purpose. The foregoing disclaimer will not apply to the extent prohibited by law.</span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                </ol>

                <p >&nbsp;</p>

                <ol start="10">
                    <li><span ><span ><strong><span
                    >INDEMNITY</span></strong></span></span></li>
                    {/* <li> */}
                    <ol>
                        <li ><span ><span
                        ><span>Partner agree to indemnify and hold the Swish Club (and its officers, employees, and agents) harmless, including costs and attorneys&rsquo; fees, from any claim or demand made by any third party due to or arising out of (a) Partner use of the Platform and/or Services, (b) Partner violation of this Agreement or Agreement Documents; (c) Partner violation of applicable laws or regulations or (d) any information or data given by Partner or Partner Employee that becomes subject of third party claims.&nbsp; Swish Club reserve the right, at Partner expense, to assume the exclusive defence and control of any matter for which Partner are required to indemnify us, and Partner agree to cooperate with Swish Club defence of these claims. Partner agree not to settle any matter without the prior written consent of the Swish Club. Swish Club will use reasonable efforts to notify Partner of any such claim, action or proceeding upon becoming aware of it.</span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                    {/* <li> */}
                    <ol start="2">
                        <li ><span ><span
                        ><span>Swish Club will defend Partner against any claim, demand, suit or proceeding made or brought against Partner by a third party alleging that any part of the Platform that Partner use materially infringes or misappropriates such third party&rsquo;s intellectual property rights and will indemnify Partner from any damages, reasonable attorney fees and costs finally awarded against Partner as a result of, or for amounts paid by Partner under a settlement approved by Swish Club in writing of such claim against Partner, provided Partner: (a) promptly give Swish Club written notice of the claim against Partner; (b) give Swish Club sole control of the defence and settlement of the claim against Partner; and (c) give Swish Club all reasonable assistance, at Swish Club expense. The aforementioned defence and indemnification obligations do not apply to Partner: (a) use of the Services in violation of this Agreement, Agreement Documentation or applicable Order Forms; (b) to the extent that a claim against Partner relates to Partner wilful misconduct or gross negligence; (c) relates to the incorporation of any Service or software that Partner purchased is combined, operated with or used with, any technology (including any software, hardware, firmware, system or network) or service not provided by Swish Club or authorised for use in the Documentation; and (d) relates to the modification of any Service that Partner purchased from Swish Club other than: (i) by Swish Club in connection with this Agreement; or (ii) with Swish Club express written authorisation and in strict accordance with Swish Club written directions and specification.</span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                </ol>

                <p >&nbsp;</p>

                <ol start="11">
                    <li><span ><span ><strong><span
                    >MISCELLANEOUS</span></strong></span></span></li>
                    {/* <li> */}
                    <ol>
                        <li ><span ><span
                        ><strong><span
                        >Notices:</span></strong> <span>Any notice, information, intimation, or document required or authorised by this Agreement, shall be given in writing and shall be deemed to have been duly given or delivered:</span></span></span>
                            <ol >
                                <li ><span ><span
                                ><span>Upon delivery by hand at the addresses referred to herein below and obtaining written acknowledgement in receipt thereof; or</span></span></span>
                                </li>
                                <li ><span ><span
                                ><span>Within 3 (three) days of sending it by a recognised courier to the other Party at the addresses referred to herein below; or</span></span></span>
                                </li>
                                <li ><span ><span
                                ><span>Within 5 (five) days of sending it by registered post acknowledgement due (RPAD) to the relevant Party at the address referred to herein below; or</span></span></span>
                                </li>
                                <li ><span ><span
                                ><span>Upon sending it by electronic mail at the e-mail address provided by the Parties hereunder. </span></span></span>


                                    <p ><span ><span
                                    ><strong><span
                                    >To Partner</span></strong><span>: &nbsp;At the address mentioned in the Order Form.</span></span></span>
                                    </p>

                                    <p ><span ><span
                                    ><strong><span><span
                                    >To Swish Club</span></span></strong><span><span
                                    >:</span></span></span></span></p>

                                    <p>
                                        <span >
                                            <span>
                                                <span>
                                                    <span>Attn. :
                                                    </span>
                                                </span>
                                                {/* &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                                                <span>
                                                    <span > Legal Counsel
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </p>
                                    <p >
                                        <span >
                                            <span>
                                                <span>
                                                    <span>
                                                        Address
                                                    </span>
                                                </span>
                                                {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                                                <span>
                                                    <span>
                                                        : Hustlehub H1907
                                                    </span>
                                                </span>
                                            </span>
                                        </span><br />
                                        <span >
                                            <span >
                                                <span
                                                ><span>

                                                        {/* &nbsp; &nbsp; &nbsp;  */}
                                                        {/* &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  */}
                                                        4<sup>th</sup> Floor, 240, 19th Main Rd, 4th Sector, HSR Layout, </span></span></span></span><br />
                                        <span ><span ><span
                                        ><span
                                        >
                                                {/* &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; */}
                                                Bengaluru, Karnataka&ndash;560102</span></span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span>
                                    </p>

                                    <p ><span ><span
                                    ><span><span
                                    >Telephone</span></span>
                                        {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  */}
                                        <span
                                        ><span >: +91 81474 06671</span></span> </span></span></p>

                                    <p ><span ><span
                                    ><span><span
                                    >Email</span></span>
                                        {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                                        <span><span
                                        >: enterprise@swishclub.in</span></span>&nbsp;&nbsp;&nbsp; </span></span></p>
                                </li>
                            </ol>
                        </li>
                    </ol>
                    {/* </li> */}

                    {/* <p >&nbsp;</p> */}

                    {/* <ol> */}
                    {/* <li> */}
                    <ol start="2">
                        <li ><span ><span
                        ><strong><span>Force Majeure:</span></strong><span
                        > If Swish Club ability to provide Services is curtailed or limited, directly or indirectly by causes beyond Swish Club control (a &ldquo;Force Majeure Event&rdquo;), Swish Club inability to provide Services in whole or in part shall not constitute a breach or failure of performance under this Agreement for the period of time occasioned by any such occurrence. Upon the occurrence of such a Force Majeure Event, Swish Club shall have the right to do any or all of the following: (a) suspend performance of the Service and use commercially reasonable efforts to resume its performance as soon as is practicable after the cessation of such event; (b) upon written agreement between Partner and Us, make an equitable adjustment to the Fees to take into consideration additional costs or unanticipated additional administrative expenses which Swish Club may incur if it is able to continue to provide some or all of the Services during such event; and (c) terminate this Agreement or a particular Service if such event continues for a period of more than 30 days.</span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                    {/* <li> */}
                    <ol start="3">
                        <li ><span ><span
                        ><strong><span>Dispute resolution:</span></strong><span
                        > Any dispute arising between the Parties in relation to this Agreement, including the existence, validity and enforceability hereof, shall be finally settled by arbitration by a mutually agreed sole arbitrator appointed by the Parties in accordance with the Arbitration and Conciliation Act, 1996 (which is deemed to be incorporated into this Agreement by reference). All proceedings of such arbitration shall be in the English language. The venue of the arbitration shall be Bangalore, India. The award of the arbitrator shall be final and binding on the Parties.</span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                    {/* <li> */}
                    <ol start="4">
                        <li ><span ><span
                        ><strong><span>Entire agreement: </span></strong><span
                        >This Agreement together with all the Agreement Documents and Policies hereto, constitutes and contains the entire agreement and understanding between the Parties with respect to the subject matter hereof and supersedes all previous communications, negotiations, commitments, either oral or written between the Parties respecting the subject matter hereof.</span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                    {/* <li> */}
                    <ol start="5">
                        <li ><span ><span
                        ><strong><span>Order of Precedence</span></strong><span
                        >: In the event of any conflict or inconsistency among the following documents, the order of precedence shall be: (1) the applicable Order Form, (2) this Agreement, and (3) the Documentation.<strong> </strong></span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                    {/* <li> */}
                    <ol start="6">
                        <li ><span ><span
                        ><strong><span
                        >Assignment: </span></strong><span>Partner may not assign any of Partner rights or obligations hereunder, whether by operation of law or otherwise, without Swish Club prior written consent (not to be unreasonably withheld); provided, however, either party may assign this Agreement and the Agreement Documents in their entirety, without the other party&rsquo;s consent to its Affiliate or in connection with a merger, acquisition, corporate reorganisation, or sale of all or substantially all of its assets.</span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                    {/* <li> */}
                    <ol start="7">
                        <li ><span ><span
                        ><strong><span>Independent Contractors: </span></strong><span
                        >This Agreement shall not be interpreted or construed to be or create an association, joint venture or partnership between the Parties or to impose any partnership obligation or liability upon either party. This Agreement is being entered into on a principal-to-principal basis. Neither party shall have any right, power or authority to enter into any agreement or undertaking for, or act on behalf of, or act as or be an agent or representative of, or to otherwise bind, the other party.</span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                    {/* <li> */}
                    <ol start="8">
                        <li ><span ><span
                        ><strong><span
                        >Severability: </span></strong><span>If for any reason whatsoever, any provision of this Agreement is or becomes, or is declared by a court of competent jurisdiction to be, invalid, illegal or unenforceable, then the Parties will negotiate in good faith to agree on such provision to be substituted, which provisions shall, as nearly as practicable, leave the Parties in the same or nearly similar position to that which prevailed prior to such invalidity, illegality or unenforceability.</span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                    {/* <li> */}
                    <ol >
                        <li ><span ><span
                        ><strong><span
                        >Waiver: </span></strong><span>A waiver or any failure or delay by a party in the enforcement of the obligations, agreements, undertakings or covenants in this Agreement shall not be construed as a waiver by such party of any of its rights, unless made in writing. </span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                    {/* <li> */}
                    <ol start="10">
                        <li ><span ><span
                        ><strong><span
                        >Sub-Contractors</span></strong><span>: Swish Club may, in Swish Club sole discretion, sub contract with other persons to perform part of the Services.<strong> </strong></span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                    {/* <li> */}
                    <ol start="11">
                        <li ><span ><span
                        ><strong><span
                        >Counterparts</span></strong><span>: This Agreement may be executed in two or more counterparts, each of which shall be deemed an original and all of which together shall constitute one instrument. Counterparts of this Agreement may be delivered via facsimile, electronic mail (including adobe.pdf, docusign or any electronic signature) or other transmission method and any counterpart so delivered shall be deemed to have been duly and validly delivered and be valid and effective for all purposes.</span></span></span>
                        </li>
                    </ol>
                    {/* </li> */}
                    {/* </ol> */}
                </ol>
            </div>
        </div>
    );
};


export default EnterpriseTerms;
