import { useState } from "react"


export default function Navbar() {
    const [isNavExpanded, setIsNavExpanded] = useState(false)
    const handleOnDemoButtonClick = () => {
        window.location.href = 'https://outlook.office365.com/owa/calendar/SwishClubDemo@swishclub.in/bookings/s/oUoszJmqSE-Nmmfn9rbJIA2';
    };
    return (
        <>
            <link rel="stylesheet" type="text/css" href="/mobile/navbar.css" />
            <nav className="navigation">
                <a href="/" className="brand-name">
                    <img src="/mobile/swish-logo.svg" />
                </a>
                <button
                    className="hamburger"
                    onClick={() => {
                        setIsNavExpanded(!isNavExpanded)
                    }}
                >
                    <img src="/mobile/ham.svg" />
                </button>
                <div
                    className={
                        isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
                    }
                >
                    <ul className="nav-menu-ul">
                        {/* <div className="rectangle-parent16"> */}
                            {/* <div className="group-child19" />
                            <img className="group-icon6" alt="" src="/group6.svg" /> */}
                            <div className="group-parent4">
                                <div className="rectangle-parent17" onClick={handleOnDemoButtonClick}>
                                    <div className="rectangle5" />
                                    <div className="contact-us">Book DEMO</div>
                                </div>
                                <div className="home-about-us-container">
                                    <a href="/" className="offer-the-most-modern-holisti">{`Home `}</a>
                                    <a href="/about" className="offer-the-most-modern-holisti">{`About us `}</a>
                                    <a href="/contact" className="offer-the-most-modern-holisti">Contact us</a>
                                </div>
                                <div className="rectangle-parent18" style={{display: "none"}}>
                                    <div className="rectangle6" />
                                    <b className="contact-us2">SIGN IN</b>
                                </div>
                            </div>
                        {/* </div> */}
                        {/* <li>
                            <a href="/">Home</a>
                        </li>
                        <li>
                            <a href="/about">About</a>
                        </li>
                        <li>
                            <a href="/contact">Contact</a>
                        </li> */}
                    </ul>
                </div>
            </nav>
        </>
    );
}