import Navbar from "./navbar/navbar";
import DemoForm from "./demo-form/demo-form";
const ContactUsMobile = () => {
  return (
    <div>
      <link rel="stylesheet" type="text/css" href="/MobileViewContactUs.css" />
      <div className="mobile-view-contact-us">
        {/* <img className="navbar-icon" alt="" src="/contactUsMobile/navbar.svg" /> */}
        <Navbar />
        <div className="rectangle-parent">
          <div className="group-child" />
          <a href="/" className="brand-name">
            <img className="swish-logo-1" alt="" src="/contactUsMobile/swish-logo-1.svg" />
          </a>
          {/* <img className="social-button-icon" alt="" src="/contactUsMobile/social-button.svg" /> */}
          <div className="social-button-icon">
            <div className="social-button">
              <a href='https://twitter.com/swishclubhq'>
                <img className="twitter-icon" alt="" src="/mobile/Twitter.png" />
              </a>
              <a href='https://www.instagram.com/swishclubhq/?hl=en'>
                <img className="google-icon" alt="" src="/mobile/instagram.png" />
              </a>
              <a href='https://www.linkedin.com/company/swishclubhq/about/'>
                <img className="facebook-icon" alt="" src="/mobile/linkdIn.png" />
              </a>

            </div>
          </div>
          <div className="copyright-2023-container">
            <p className="copyright-2023-swish">
              <span className="copyright-2023">Copyright © 2023 Swish</span>
            </p>
            <p className="copyright-2023-swish">
              <span> BenPlat EPP Technology Private Limited</span>
            </p>
            <p className="copyright-2023-swish">
              <span>
                {/* <a className="terms-of-service" href={"/terms"}>Terms Of Service</a> */}
                All rights reserved
              </span>
            </p>
          </div>
        </div>
          <img
            className="paid-language-instructor-1"
            alt=""
            src="/contactUsMobile/paid-language-instructor-1@2x.png"
          />
        {/* <b className="contact-us">Contact us</b> */}
        <div className="group-parent">
          <div className="our-address-registered">
            <b className="our-address">Registered Address</b>
            <div className="pebda-parkway">
              <p className="copyright-2023-swish">
              GP1 205 Green Park Regency,42/5,
              </p>
              <p className="copyright-2023-swish">
                {" "}
                Near Amrrutha, Carmelram, Bangalore South, KA
              </p>
            </div>
            <img className="icon" alt="" src="/contactUsMobile/icon.svg" />
          </div>
          <div className="our-address-parent">
            <b className="our-address">Office Address</b>
            <div className="pebda-parkway">
              <p className="copyright-2023-swish">
                Hustlehub H1907, 240, 19th Main Rd,
              </p>
              <p className="copyright-2023-swish">
                {" "}
                4th Sector, HSR Layout, Bengaluru, 560102
              </p>
            </div>
            <img className="icon" alt="" src="/contactUsMobile/icon.svg" />
          </div>
          <div className="contact-info-parent">
            <b className="contact-info">Contact Info</b>
            <a
              className="open-a-chat-container"
              href="tel:+918147406671"
              target="_blank"
            >
              <p className="copyright-2023-swish">{`+91 8147406671 `}</p>
              <p className="copyright-2023-swish">(Timing: Mon-Sat: 9am-8pm)</p>
            </a>
            <img className="icon1" alt="" src="/contactUsMobile/icon1.svg" />
          </div>
          <div className="live-support-parent">
            <b className="live-support">Live Support</b>
            <div className="live-chat-service-container">
              <p className="copyright-2023-swish">live chat service</p>
              <p className="copyright-2023-swish">
                <a
                  className="supportswishclubin1"
                  href="mailto:support@swishclub.in"
                  target="_blank"
                >
                  <span className="terms-of-service">support@swishclub.in</span>
                </a>
              </p>
            </div>
            <img className="icon2" alt="" src="/contactUsMobile/icon2.svg" />
          </div>
        </div>
        <div className="rectangle-group">
          <div className="group-item" />
          <DemoForm />
          <b className="dont-hesitate-to">
            We're dedicated to providing you with an optimal experience.
          </b>
          <div className="dont-hesitate-to1">
            To customise this experience for you, we'd love for you to answer a
            few brief questions
          </div>
          <img className="layer-0-2" alt="" src="/contactUsMobile/layer-0-2@2x.png" />
        </div>
      </div>
    </div>
  );
};

export default ContactUsMobile;
