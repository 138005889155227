import MobileView from "../components/mobile/MobileView";
import Desktop from "../components/desktop/page/desktop";
import { useEffect, useState } from 'react';
const Dark = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    // Update state when the screen size changes
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            {
                (isMobile) ?
                <MobileView />:
                <Desktop/>
            }

        </div>
    );
};

export default Dark;
