import { useState, useEffect } from 'react';

const BenefitsBanner = ({ bannerData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = bannerData.length;
  const handleAutomaticChange = () => {
    setCurrentPage((prevPage) => (prevPage % totalPages) + 1);
  };

  useEffect(() => {
    const interval = setInterval(handleAutomaticChange, 3000);

    return () => clearInterval(interval);
  }, [currentPage, totalPages]);
  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  const currentBanner = bannerData[currentPage - 1];
  return (<div className="dark-inner">
    <div className="rectangle-parent6">
      <div className="group-child6" style={{ background: currentBanner.backgroundColor }} />
      <div className="image-group">
        <img className="image-icon1" alt="" src={currentBanner.image} />

        <div className="text">
          <div className="lorem-ipsum-dolor">
            {currentBanner.text}
          </div>
          <div className="next-previous">
            <img
              className="next-previous-child"
              alt=""
              src="/group-13725.svg"
              onClick={() => {
                handleChangePage((prevPage) => {
                  let newPage = (prevPage - 1 + totalPages) % totalPages;
                  if (newPage == 0 ){
                    newPage = totalPages;
                  }
                  return newPage;
                });
              }}
            />
            <img
              className="next-previous-item"
              alt=""
              src="/group-13729.svg"
              onClick={() => handleChangePage((prevPage) => (prevPage % totalPages) + 1)}
            />
          </div>
          <div >
            <b className="best-online-agency">
              {currentBanner.title}
            </b>
            {currentBanner.subTitle ?
              (
                <b className="banner-sub-title" >
                  {currentBanner.subTitle}
                </b>
              ) :
              (
                <b></b>
              )
            }
          </div>
        </div>
      </div>
    </div>
  </div>);
}

export default BenefitsBanner;