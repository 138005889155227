import Header from "./desktop/header/header";
import Navbar from "./mobile/navbar/navbar";
import { useEffect, useState } from 'react';
import AccountDeletionPolicyMobile from './mobile/AccoutDeletionPolicy';
import AccountDeletionPolicyDesktop from "./desktop/page/AccountDeletionPolicyDesktop";


const AccountDeletionPolicy = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

    // Update state when the screen size changes
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 600);
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            {
                (isMobile) ?
                    <AccountDeletionPolicyMobile /> :
                    <AccountDeletionPolicyDesktop />
            }

        </div>
    );
};

export default AccountDeletionPolicy;