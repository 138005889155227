import React from "react"

const MetricBlog = ({ metricdata }) => {
    return (
        <div >
            {
                metricdata.length > 0 && metricdata.map((data) => {
                    return (
                        <div key={data.id} className={data.className1}>
                            <b className="e-retention">{data.title}</b>
                            <div className="propel-your-company">
                                {data.label}
                            </div>
                            <div className="div">{data.value}</div>
                            <img className="arrow-icon" alt="" src="/mobile/arrow-1.svg" />
                        </div>
                    )
                })
            }
        </div>
    )
}

export default MetricBlog