const AppPrivacy = () => {
    return (
        <>
            <link rel="stylesheet" type="text/css" href="/style/terms.css" />

            <img src="/mobile/swish-logo.svg" />
            <div
                className="entry-content clear"
                ast-blocks-layout="true"
                itemProp="text"
                data-contrast="none"
                style={{ fontSize: "16px" }}
            >
                <p>
                    BenPlat EPP Technology Pvt Ltd., a company incorporated
                    under the Companies Act, 2013 with its registered office at
                    Hustlehub H1907, 4th Floor, 240, 19th Main Rd, 
                    4th Sector, HSR Layout, Bengaluru, Karnataka 560102
                    (hereinafter referred to as “we”, “us”, “our” or “Company”)
                    recognizes the importance of privacy and confidentiality.
                    This policy outlines the collection, use, and protection of
                    personal information from users (hereinafter referred to as
                    “User” or “you” or “your”) while accessing, using, or
                    availing any services from its technology platform, website{" "}
                    <a href="https://www.swishclub.in">
                        (https://www.swishclub.in)
                    </a>{" "}
                    or any other subdomain of the same and mobile application
                    thereof (collectively referred to as “Platform”). This
                    privacy policy describes the type of information that the
                    Company may collect from you when you access or use the
                    Platform and the practices for collecting, using,
                    maintaining, protecting, and disclosing such information.
                </p>
                <p>
                    By browsing, accessing, or using the Platform or its
                    services and/or registering an account with the Platform,
                    you agree to this privacy policy, consenting to the
                    Company’s collection, use, disclosure, retention, and
                    protection of your personal information as described here.
                    If you do not provide the information we may require, we may
                    not be able to provide all our services to you. If you do
                    not agree with any of the terms of this privacy policy,
                    please do not use the Platform.
                </p>
                <ol style={{ fontSize: "22px" }}>
                    <li>
                        COLLECTION OF INFORMATION
                        <br />
                        <p style={{ fontSize: "16px" }}>
                            We gather personally identifiable information (such
                            as email ID, name, phone number, office email ID.)
                            when you create an account on the Platform. While
                            some sections of our site are accessible without
                            registration, certain activities, like placing an
                            order, require registration. Your contact
                            information is utilized to send you offers based on
                            your previous orders, interests and any new offers.
                            Additionally, we may keep a record of your product
                            interests and acquire information for our present
                            and/or future affiliates.
                        </p>
                        <p style={{ fontSize: "16px" }}>
                            We may collect various types of information from and
                            about you, including but not limited to:
                        </p>
                        <ol style={{ fontSize: "16px" }} type="a">
                            <li>
                                Personal Information: Any information,
                                documents, or details that can lead to your
                                identification, directly or indirectly,
                                including name, address, postal code, recent
                                photograph, financial information, phone number,
                                fax number, email address, and any other
                                sensitive personal data as defined under
                                Information Technology (Reasonable security
                                practices and procedures and sensitive personal
                                data or information) Rules, 2011.
                            </li>
                            <li>
                                Information provided on the Platform for
                                availing services.
                            </li>
                            <li>
                                Information about your geographical location,
                                internet connection, equipment/device used to
                                access the Platform, other apps installed on the
                                mobile, browser type, and usage details.
                            </li>
                            <li>
                                Transaction Information: In connection with any
                                communication, transaction, payment services, or
                                other services availed through the Platform,
                                information such as bank account numbers,
                                billing and delivery information, credit/debit
                                card numbers, expiration dates, and tracking
                                information may be collected to facilitate
                                transactions.
                            </li>
                            <li>
                                Details of communications or transactions,
                                product types and specifications, pricing, and
                                delivery information.
                            </li>
                            <li>
                                Search history, browsing activities on the
                                Platform, including IP addresses, browsing
                                patterns, and user behavioral patterns.
                            </li>
                            <li>
                                Communications between you and us or with
                                third-party service providers on the Platform.
                            </li>
                            <li>
                                Content, feedback, reviews, etc., uploaded or
                                provided to the Company or on the Platform.
                            </li>
                            <li>
                                Statistical information and details of visitors
                                to the Platform, including IP addresses, browser
                                software, operating system, software and
                                hardware attributes, pages viewed, and number of
                                sessions.
                            </li>
                            <li>
                                Access to your images and videos on your device,
                                camera, mic, Bluetooth, and device location when
                                using or accessing the Platform.
                            </li>
                            <li>
                                Location and Device Information: Automatically
                                receiving, collecting, and analyzing your
                                location information through methods such as IP
                                address, and collecting information about your
                                device and its usage.
                            </li>
                            <li>
                                <p>
                                    Access to Information from Third Parties:
                                    You may choose to provide access to certain
                                    information stored by third parties like
                                    social networking sites or e-commerce
                                    websites. We will have access to such
                                    information as permitted by the user’s
                                    privacy settings on that site and the user’s
                                    specific authorization.
                                </p>
                                <p>
                                    Failure to provide sufficient information
                                    during registration may impede the process
                                    or limit access to the Platform, its
                                    services, or information about our products
                                    and services.
                                </p>
                                <p>
                                    This information may be collected directly
                                    from you or automatically as you navigate
                                    through the Platform, including usage
                                    details, IP addresses, and information
                                    obtained through cookies, web beacons, and
                                    other tracking technologies. If adequate
                                    personal information is not provided, we may
                                    be unable to complete the registration
                                    process or enable full access to the
                                    Platform, its services, or provide
                                    comprehensive information about our products
                                    and services.
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        Information we collect
                        <ol type="I" style={{ fontSize: "16px" }}>
                            <li>Your name, phone number and email address;</li>
                            <li> Account, purchase and payment information;</li>
                            <li>Candidate information (for job applicants);</li>
                            <li>
                                Access to securely stored third party
                                credentials (for triggering notifications);
                            </li>
                            <li> Location data (including IP Address);</li>
                            <li>Device data; and </li>
                            <li>
                                Other data collected that could directly or
                                indirectly identify you.
                            </li>
                        </ol>
                    </li>
                    <li>
                        How do we store your data? (If you are located in other
                        locations except EU)
                        <br />
                        <p style={{ fontSize: "16px" }}>
                            Our Company securely stores your data at a Secure
                            storage location in India.
                        </p>
                    </li>
                    <li>
                        USE OF INFORMATION
                        <br />
                        <p style={{ fontSize: "16px" }}>
                            If you provide us with information, you are
                            considered to have agreed and expressly consented to
                            us collecting, retaining, and using your information
                            for the purposes mentioned below, including but not
                            limited to:
                        </p>
                        <ol type="a" style={{ fontSize: "16px" }}>
                            <li>
                                Verifying the identity of the User onboarded on
                                the Platform.
                            </li>
                            <li>
                                Facilitating your use of the Platform and
                                providing services to you.
                            </li>
                            <li>
                                Processing the user’s registration by providing
                                the User with a log-in ID and/or one-time
                                password for accessing the Platform.
                            </li>
                            <li>
                                Communicating and sharing necessary product
                                and/or service-related information from time to
                                time.
                            </li>
                            <li>
                                Allowing you to receive customer care services
                                and responding to your queries, claims,
                                disputes, or any other concerns.
                            </li>
                            <li>
                                Administering our services and diagnosing
                                technical problems.
                            </li>
                            <li>
                                Carrying out our obligations and enforcing our
                                rights arising from any contracts entered into
                                between you and us, including billing and
                                collection.
                            </li>
                            <li>
                                Complying with applicable laws, rules, and
                                regulations.
                            </li>
                            <li>
                                Providing you with information regarding offers
                                on products and services, updates, promotions,
                                and schemes related to our services or
                                associated with our service providers, group
                                companies, affiliates, and/or partners, that we
                                believe would be of interest to you.
                            </li>
                            <li>
                                Performing research or statistical analysis to
                                improve the content and layout of the Platform,
                                as well as our product offerings and services.
                            </li>
                            <li>
                                Performing research or statistical analysis to
                                improve the content and layout of the Platform,
                                as well as our product offerings and services.
                            </li>
                            <li>
                                Conducting assessment, evaluation, statistical,
                                market and product analysis, and/or market
                                research to monitor, improve, and administer the
                                Platform and its services.
                            </li>
                            <li>
                                Responding to your inquiries or fulfilling your
                                requests for information about the various
                                services ordered on the website.
                            </li>
                            <li>
                                Processing and completing the orders placed with
                                third-party sellers on the Platform and sharing
                                information as needed to process and complete
                                the orders.
                            </li>
                            <li>
                                Ensuring that orders are successfully delivered
                                on time and keeping you informed about the
                                status of your order.
                            </li>
                            <li>
                                Sending you important information regarding the
                                website, changes in terms and conditions, user
                                agreements, and policies, and/or other
                                administrative information.
                            </li>
                            <li>
                                Sending you surveys and marketing
                                communications.
                            </li>
                            <li>
                                Personalizing your experience on the Platform by
                                presenting advertisements, services, and orders
                                tailored to your preferences.
                            </li>
                            <li>
                                Helping you address problems incurred on the
                                Platform, including addressing any technical
                                problems.
                            </li>

                            <li>
                                If you avail any service from the Platform,
                                completing and fulfilling the same.
                            </li>
                            <li>
                                Protecting the integrity and properly
                                administering the Platform.
                            </li>
                            <li>
                                Conducting internal reviews and data analysis
                                for the Platform.
                            </li>
                            <li>
                                Responding to legal, judicial, quasi-judicial
                                processes, and providing information to law
                                enforcement agencies or in connection with an
                                investigation on matters related to public
                                safety, as permitted by law.
                            </li>
                            <li>
                                Implementing information security practices.
                            </li>
                            <li>
                                Determining any security breaches, computer
                                contaminants, or computer viruses.
                            </li>
                            <li>
                                Investigating, preventing, or taking action
                                regarding illegal activities and suspected
                                fraud.
                            </li>
                            <li>
                                Undertaking forensics of the computer resource
                                concerned as a part of investigation or internal
                                audit.
                            </li>
                            <li>
                                Tracing computer resources or any person who may
                                have contravened or is suspected of having or
                                being likely to contravene, any provision of
                                law, including the Information Technology Act,
                                2000, that is likely to have an adverse impact
                                on the services provided on any website or by
                                the Company.
                            </li>
                            <li>
                                {" "}
                                Enabling a potential service recipient or
                                investor to evaluate the business of the
                                Company.
                            </li>
                        </ol>
                        <p style={{ fontSize: "16px" }}>
                            Individually and collectively referred to as
                            “purposes,” you hereby agree and acknowledge that
                            the information collected is for a lawful purpose
                            connected with a function or activity of the Company
                            or any person on its behalf, and the collection of
                            information is necessary for these purposes.
                        </p>
                        <p style={{ fontSize: "16px" }}>
                            We use the information provided by you for the
                            provision of services. To the extent we use your
                            information for the above-mentioned purposes, and to
                            the extent permissible by applicable laws, we will
                            provide you with the ability to opt out of such
                            uses.
                        </p>
                        <p style={{ fontSize: "16px" }}>
                            In our efforts to continually improve our product
                            and service offerings, we collect and analyze
                            demographic and profile data about our users’
                            activity on our website. We identify and use your IP
                            address to help diagnose problems with our server
                            and to administer our website. Your IP address is
                            also used to help identify you and to gather broad
                            demographic information. We will occasionally ask
                            you to complete optional online surveys. Surveys may
                            ask you for contact information and demographic
                            information (like zipcode, age, or income level). We
                            use this data to tailor your experience at our site,
                            providing you with content that we think you might
                            be interested in and to display content according to
                            your preferences.
                        </p>
                    </li>
                    <li>
                        User rights
                        <br />
                        <div style={{ fontSize: "16px" }}>
                            <p style={{ fontSize: "16px" }}>
                                Our Company wants to ensure you are fully aware
                                of your data protection rights. Every user is
                                entitled to the following:
                            </p>
                            <p style={{ fontSize: "16px" }}>
                                The right to access - You have the right to
                                request Our Company copies of your data. We may
                                charge you a small fee for this service.
                            </p>
                            <p style={{ fontSize: "16px" }}>
                                The right to rectification - You have the right
                                to request that Our Company correct any
                                information you believe is inaccurate. You also
                                have the right to request Our Company to
                                complete information you believe needs to be
                                completed.
                            </p>

                            <p style={{ fontSize: "16px" }}>
                                The right to erasure — You have the right to
                                request that Our Company erase your data, under
                                certain conditions.
                            </p>
                            <p style={{ fontSize: "16px" }}>
                                The right to restrict processing - You have the
                                right to request that Our Company restrict the
                                processing of your personal data, under certain
                                conditions, provided this information is not
                                crucial for the functioning of Swish Club in the
                                process of execution of the services.
                            </p>
                            <p style={{ fontSize: "16px" }}>
                                The right to object to processing - You have the
                                right to object to Our Company's processing of
                                your data, under certain conditions, provided
                                this information is not crucial for the
                                functioning of Swish Club in the process of
                                execution of the services.
                            </p>
                            <p style={{ fontSize: "16px" }}>
                                The right to data portability - You have the
                                right to request that Our Company transfer the
                                data that we have collected to another
                                organization, or directly to you, under certain
                                conditions.
                            </p>
                            <p style={{ fontSize: "16px" }}>
                                If you make a request, we have one month to
                                respond to you. If you would like to exercise
                                any of these rights, please email us at:
                                support@swishclub.in
                            </p>
                        </div>
                    </li>
                    <li>
                        Consent Management:
                        <ul style={{ fontSize: "16px" }}>
                            <li>
                                Obtaining Consent:
                                <br />{" "}
                                <p style={{ fontSize: "16px" }}>
                                    When you visit our website or platform, we
                                    will request your consent before collecting
                                    any personal information. Clear and easily
                                    understandable explanations will be provided
                                    regarding the purpose and scope of data
                                    processing activities. You have the right to
                                    grant or deny consent.
                                </p>
                                <li>
                                    Modification of Consent:
                                    <br />{" "}
                                    <p style={{ fontSize: "16px" }}>
                                        If you wish to modify your consent for
                                        data processing, You can do so by
                                        contacting Swish Club support.
                                        Modification will not affect the
                                        lawfulness of any processing based on
                                        prior consent.
                                    </p>
                                </li>
                                <li>
                                    Withdrawal of Consent:
                                    <br />{" "}
                                    <p style={{ fontSize: "16px" }}>
                                        If you wish to withdraw your consent for
                                        data processing, you can do so by
                                        contacting Swish Club support.
                                        Withdrawal will not affect the
                                        lawfulness of any processing based on
                                        prior consent.
                                    </p>
                                    <p style={{ fontSize: "16px" }}>
                                        By incorporating this consent management
                                        facility, we aim to empower you with
                                        control over your personal data,
                                        ensuring transparency and compliance
                                        with privacy regulations.
                                    </p>
                                </li>
                            </li>
                        </ul>
                    </li>
                    <li>
                        DISCLOSURE OF USER INFORMATION
                        <p style={{ fontSize: "16px" }}>
                            You acknowledge our right to disclose the
                            information collected or provided by you, as
                            outlined in this Policy, through the following
                            means:
                        </p>
                        <ol style={{ fontSize: "16px" }} type="a">
                            <li>
                                To our subsidiaries, group companies, and/or
                                affiliates under the common ownership or control
                                of our parent company.
                                <li>
                                    To third-party service providers engaged for
                                    our business and operational needs or to
                                    fulfill any lawful contractual obligation
                                    with them.
                                </li>
                                <li>
                                    In connection with the services provided
                                    through the Platform.
                                </li>
                                <li>
                                    To third-party service providers, whether
                                    located within or outside India, who perform
                                    various services on our behalf, including
                                    but not limited to data entry, database
                                    management, promotions, marketing
                                    activities, product and service alerts,
                                    authentication, and verification services.
                                    These third- party service providers are
                                    bound by confidentiality obligations and are
                                    authorized to use your Personal Information
                                    solely for the purposes specified in clause
                                    II mentioned above.
                                </li>
                                <li>
                                    To our professional advisers, law
                                    enforcement agencies, insurers, government
                                    and regulatory bodies, and other
                                    organizations, or in compliance with court
                                    orders, requests/orders, notices from legal
                                    authorities, or law enforcement agencies,
                                    compelling us to make such disclosures.
                                </li>
                                <li>
                                    To third parties for marketing or making
                                    their products or services available to you,
                                    provided you have consented to receive such
                                    updates. We contractually obligate these
                                    third parties to maintain the
                                    confidentiality of Personal Information and
                                    use it solely for the disclosed purposes.
                                </li>
                                <li>
                                    To third parties providing logistics
                                    services, delivery services, payment
                                    services, etc., while placing your order on
                                    the Platform to complete and fulfill your
                                    transaction.
                                </li>
                                <li>
                                    To assist in investigating, preventing, or
                                    taking action against unlawful and illegal
                                    activities, suspected fraud, potential
                                    threats to the safety or security of any
                                    person or other users, violations of the
                                    Platform’s terms of use, or to defend
                                    against legal claims.
                                </li>
                                <li>
                                    Sharing information with third parties such
                                    as banks, financial institutions, credit
                                    agencies, or vendors to enable them to offer
                                    their products or services to you. While we
                                    strive to implement internal procedures to
                                    secure information from intruders, it is not
                                    guaranteed that such measures can eliminate
                                    all risks of theft, loss, or misuse.
                                </li>
                                <li>
                                    Sharing your information when mandated by
                                    applicable law. This may involve exchanging
                                    information with other companies,
                                    organizations, banks, government, or
                                    regulatory authorities. However, this does
                                    not include selling, renting, or disclosing
                                    information for commercial purposes contrary
                                    to applicable laws.
                                </li>
                                <li>
                                    If disclosure is deemed necessary or
                                    appropriate to protect the rights, property,
                                    or safety of the Company, our customers, or
                                    others. This includes exchanging information
                                    with other companies and organizations for
                                    fraud protection and credit risk reduction.
                                </li>
                                <li>
                                    Sharing your information with any third
                                    party under circumstances where we have
                                    obtained your consent.
                                </li>
                                <li>
                                    Sharing aggregated anonymized (and
                                    de-identified) information about you with
                                    third parties at our sole discretion.
                                </li>
                                <li>
                                    The Company may share statistical data and
                                    other details (excluding your Personal
                                    Information) without your express or implied
                                    consent to facilitate various programs or
                                    initiatives launched by the Company, its
                                    affiliates, agents, third-party service
                                    providers, partners, or banks & financial
                                    institutions. This may involve
                                    transferring/disclosing/sharing information
                                    (excluding your Personal Information) to
                                    parties supporting our business, such as
                                    providing technical infrastructure services,
                                    analyzing service usage, offering merchant
                                    services, facilitating payments, or
                                    conducting academic research and surveys.
                                    These affiliates and third-party service
                                    providers are bound by confidentiality
                                    obligations consistent with this privacy
                                    policy.
                                </li>
                                <li>
                                    Sharing some or all of your Personal
                                    Information with another business entity in
                                    the event of our (or our assets’) merger,
                                    acquisition, reorganization, amalgamation,
                                    or business restructuring. In such a
                                    transaction, the other business entity (or
                                    the new combined entity) will be required to
                                    adhere to this privacy policy concerning
                                    your Personal Information.
                                </li>
                            </li>
                        </ol>
                    </li>
                    <li>
                        THIRD PARTY OFFERS
                        <br />
                        <ol type="a" style={{ fontSize: "16px" }}>
                            <li>
                                We may establish relationships with other
                                parties and websites to offer you the benefit of
                                products and services which we do not offer. We
                                may offer you access to these other parties
                                and/or their websites.
                            </li>
                            <li>
                                This policy does not apply to the products and
                                services enabled or facilitated by such third
                                parties. The privacy policies of those other
                                parties may differ, and we have no control over
                                the information that you may submit to those
                                third parties.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Third party payment services and information collection
                        <br />
                        <ol type="a" style={{ fontSize: "16px" }}>
                            <li>
                                We use a third party payment platform and
                                payment aggregator services to bill you for
                                products and Services. We may use and share the
                                User Information with reliable and reputed
                                third-party payment gateway to whom we are
                                associated in order to ensure swift and
                                comfortable payment mechanism for the User. The
                                third party service providers shall provide the
                                Company the phone number of the User utilised
                                while completing the payment transaction.
                            </li>
                            <li>
                                After the User is directed to the payment
                                mechanism and the payment gateway, the Company
                                is not liable for any data stored, used or
                                accessed by such a third party service provider
                                and the User Information is no longer governed
                                by this Policy or our Website’s Terms of
                                Service. The same shall be subject to the terms
                                and conditions and privacy policy of the third
                                party service provider. For these third party
                                service providers, we recommend that you read
                                their privacy policies in order to understand
                                the manner in which your personal information
                                and your credit/debit card details will be
                                handled by these providers.
                            </li>
                        </ol>
                    </li>
                    <li>
                        {" "}
                        Sharing of Data with Third Parties <br />
                        <ol type="a" style={{ fontSize: "16px" }}>
                            <li>
                                We will share your information with third
                                parties only in the ways that are described in
                                this Policy. We may use the individual data and
                                behaviour patterns combined with personal
                                information to provide you with personalized
                                content, and better your objectives.
                            </li>
                            <li>
                                {" "}
                                The Company may provide and utilise the
                                Information and data collected to certain third
                                parties for undertaking data analysis via third
                                party analytical tools. The third party
                                analytical tools are utilised in order to
                                analyse the data and information to personalize,
                                drive insights and thereby provide a better
                                performance, improve the quality of features and
                                provide seamless services to the User.
                            </li>
                            <li>
                                The Company does not sell, trade or rent your
                                Information to any third party unless, we have
                                been expressly authorized by you either in
                                writing or electronically to do so.
                            </li>
                            <li>
                                We share your data with the following third
                                parties for the above-mentioned purposes:
                                <br />
                                <ol type="I" style={{ fontSize: "16px" }}>
                                    <li>
                                        Google Analytics
                                        <br />{" "}
                                        <p style={{ fontSize: "16px" }}>
                                            We use Google Analytics, a web
                                            analysis service provided by Google
                                            Inc. (Google). Google Analytics uses
                                            cookies, which are text files saved
                                            on your computer that is an aid as
                                            to the data analysis of your usage
                                            patterns. You can find additional
                                            information on Google Analytics’ use
                                            of data and data protection at
                                            https://marketingplatform.google.com/about/analytics/terms/de/.
                                        </p>
                                    </li>
                                    <li>
                                        New relic <br />{" "}
                                        <p style={{ fontSize: "16px" }}>
                                            We utilize New Relic for product
                                            performance analytics purposes,
                                            enabling us to gain insights,
                                            optimize and continually monitor and
                                            improve the service provided based
                                            on the dynamic feedback received on
                                            the application
                                        </p>
                                    </li>
                                    <li>
                                        Clarity
                                        <br />{" "}
                                        <p style={{ fontSize: "16px" }}>
                                            We use Clarity for web and app
                                            analytics in order to analyse the
                                            basis for making improvements on the
                                            user experience ("UX”) of our
                                            website.
                                        </p>
                                    </li>
                                    <li>
                                        Hubspot
                                        <br />{" "}
                                        <p style={{ fontSize: "16px" }}>
                                            We utilize Hubspot for customer
                                            lifecycle and data tracking
                                            purposes, allowing us to effectively
                                            track the journey of our customers
                                            and their associated data.
                                        </p>
                                    </li>
                                    <li>
                                        Clevertap
                                        <br />{" "}
                                        <p style={{ fontSize: "16px" }}>
                                            We use Clevertap for web and app
                                            analytics in order to analyse the
                                            basis for making improvements on the
                                            user experience ("UX”) of our
                                            website.
                                        </p>
                                    </li>
                                    <li>
                                        Firebase
                                        <br />{" "}
                                        <p style={{ fontSize: "16px" }}>
                                            We use Clevertap for app analytics,
                                            and push notifications in order to
                                            analyse and send push notifications
                                            on the basis of the user level
                                            analytics.
                                        </p>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>
                        RETENTION OF INFORMATION
                        <br />
                        <p style={{ fontSize: "16px" }}>
                            We will retain your information for as long as it is
                            necessary to fulfill the purposes set forth herein
                            and for a reasonable time thereafter for the
                            furtherance and completion of any of our services to
                            you, and for such time as may be necessary to comply
                            with any legal obligation or compliance under
                            applicable laws.
                        </p>
                    </li>
                    <li>
                        Data breach
                        <br />
                        <p style={{ fontSize: "16px" }}>
                            In case of any data breach of personal data or
                            Information/Website’s database / third- party data,
                            processors is apparent, we will inform all relevant
                            authorities within 72 hours about the same. Further,
                            we shall inform you about the same without any undue
                            delay, if required.
                        </p>
                    </li>
                    <li>
                        COOKIES
                        <br />
                        <p style={{ fontSize: "16px" }}>
                            We may use data collection devices such as “cookies”
                            on certain pages to help analyze our web page flow,
                            measure promotional effectiveness, and promote trust
                            and safety. “Cookies” are small files or a small
                            amount of data that is sent to your browser and
                            stored on your device.
                        </p>
                    </li>
                    <li>
                        REVIEW OF INFORMATION
                        <br />
                        <p style={{ fontSize: "16px" }}>
                            You may review, correct, update, change, or delete
                            your information, through the login page on the
                            Platform (collectively referred to as “your
                            account”). You can request a change in the
                            information provided to us by contacting us at
                            <a href="mailto:support@swishclub.in">support@swishclub.in</a>
                        </p>
                    </li>
                    <li>
                        SECURITY
                        <br />
                        <p style={{ fontSize: "16px" }}>
                            We have implemented appropriate electronic and
                            managerial procedures to protect your Personal
                            Information, aiming to prevent unauthorized access
                            and maintain data security. These measures consider
                            the sensitivity of the collected information and the
                            current technological landscape. Adhering to widely
                            accepted industry standards, we employ safeguards
                            for information submitted to us, both during its
                            transmission and upon receipt. While we strive to
                            ensure the confidentiality of your personally
                            identifiable information, it’s important to note
                            that internet transmissions cannot be entirely
                            secure. By using this Platform, you acknowledge that
                            the Platform holds no liability for the disclosure
                            of your information resulting from transmission
                            errors or unauthorized actions by third parties.
                        </p>
                        <p style={{ fontSize: "16px" }}>
                            Despite the provisions in this policy, we are not
                            accountable for:
                        </p>
                        <ol type="a" style={{ fontSize: "16px" }}>
                            <li>
                                Security breaches on third-party websites or
                                applications, or actions of third parties
                                receiving your Personal Information.
                            </li>
                            <li>
                                Loss, damage, or misuse of your Personal
                                Information due to a Force Majeure Event. For
                                the purposes of this policy, a “Force Majeure
                                Event” encompasses events beyond our reasonable
                                control, including acts of God, fires,
                                explosions, wars, hostilities, insurrections,
                                revolutions, strikes, labor unrest, earthquakes,
                                floods, pandemics, epidemics, regulatory or
                                quarantine restrictions, unforeseeable
                                governmental restrictions or controls, or
                                failure by a third-party hosting provider or
                                internet service provider, or due to changes or
                                defects in your computer system’s software
                                and/or hardware.
                            </li>
                        </ol>
                    </li>
                    <li>
                        RIGHT TO WITHDRAW CONSENT <br />
                        <p style={{ fontSize: "16px" }}>
                            You agree that you are sharing your information
                            voluntarily. You retain the option to refrain from
                            providing or allowing the Company to collect your
                            personal information. Additionally, you can withdraw
                            your consent for the information provided at any
                            time by sending an email to the designated grievance
                            officer or the specified electronic address.
                        </p>
                        <p style={{ fontSize: "16px" }}>
                            Upon withdrawal of your consent, we may choose not
                            to provide the services for which the information
                            was initially collected. The effectiveness of your
                            withdrawal is contingent upon receiving
                            acknowledgment from us. It’s important to note that,
                            even after consent withdrawal, we might need to
                            retain the information to comply with applicable
                            laws or utilize it as outlined in Clause V
                            (Retention of Information) above.
                        </p>
                    </li>
                    <li>
                        UPDATING THE INFORMATION
                        <br />
                        <p style={{ fontSize: "16px" }}>
                            For correction or updating of any information you
                            have supplied, the Platform provides an online
                            option or contact with the Grievance Officer. If you
                            encounter a loss of access details, you can email
                            the Grievance Officer.
                        </p>
                        <p style={{ fontSize: "16px" }}>
                            Please note that updating information in an
                            application on our Platform is not possible after a
                            decision has been made. However, you have the option
                            to create and submit a new application with the
                            updated information.
                        </p>
                    </li>
                    <li>
                        CHANGES TO THIS POLICY
                        <br />
                        <p style={{ fontSize: "16px" }}>
                            We reserve the right to amend, restate, modify, or
                            replace this policy periodically. Your ongoing use
                            of the Platform and its services following any
                            changes constitutes acceptance of those
                            modifications. It is recommended to check this
                            policy periodically for updates. Once posted on the
                            Platform, the new policy will take immediate effect.
                            You agree that any information we hold about a user
                            will be governed by the latest version of the
                            policy.
                        </p>
                    </li>
                    <li>
                        MINORS
                        <br />
                        <p style={{ fontSize: "16px" }}>
                            While the Platform and its contents are not intended
                            for users under 18 years of age, we acknowledge our
                            inability to distinguish the age of individuals
                            accessing our Platform. If we discover that a person
                            submitting information is below the permissible age,
                            we will promptly delete the account and any
                            associated information. If you suspect that we may
                            have information from or about a user below the
                            permissible age, please contact the Grievance
                            Officer.
                        </p>
                    </li>
                    <li>
                        GRIEVANCE OFFICER
                        <br />
                        <p style={{ fontSize: "16px" }}>
                            In accordance with the Information Technology Act
                            2000 and rules made thereunder as may be prevailing,
                            from time to time, the name and contact details of
                            the grievance officer are provided below:
                        </p>
                    </li>
                </ol>
                <p style={{ fontSize: "16px" }}>Name: Mr. Dushyant Sapre</p>
                <p style={{ fontSize: "16px" }}>
                    Email: <a href="mailto:grievance@swishclub.in">grievance@swishclub.in</a>
                </p>
                <p style={{ fontSize: "16px" }}>
                    Grievance Officer, BenPlat EPP Technology Pvt Ltd.
                </p>
                <p style={{ fontSize: "16px" }}>
                    Hustlehub H1907, 4th Floor, 240, 19th Main Rd, 
                    4th Sector, HSR Layout, Bengaluru, Karnataka 560102
                </p>
            </div>
        </>
    );
};

export default AppPrivacy;
