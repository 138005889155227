const data = {
    "metricData": [
        {
            "id": 1,
            "title": "Retention",
            "label": "Propel Your Company Forward with a 60% Boost in Employee Retention via Swish.",
            "value": "60%",
            "icon": "",
            "className1": "e-retention-parent",
            "className2": "e-retention",
            "className3": "propel-your-company",
        },
        {
            "id": 2,
            "title": "NPS",
            "label": "Elevating Employee - NPS by 40%, the secret to satisfied teams. ",
            "value": "40%",
            "icon": "",
            "className1": "e-nps-parent",
            "className2": "e-retention",
            "className3": "propel-your-company",
        },
        {
            "id": 3,
            "title": "Savings",
            "label": "Enhance employee perks: Unlock Upto 25% savings for your employees effortlessly.",
            "value": "25%",
            "icon": "",
            "className1": "e-savings-parent",
            "className2": "e-savings",
            "className3": "enhance-employee-perks",
        }
    ],
    "employerBenefitTags": [
        {
            "id": 1,
            "value": "ZERO Cost"
        },
        {
            "id": 2,
            "value": "ZERO Obligation"
        },
        {
            "id": 3,
            "value": "ZERO Administration"
        }
    ],
    "bannerData": [
        {
            id: 2,
            title: "E-Commerce",
            subTitle: "Unlock Exclusive Savings for your employees",
            text: `Discover unparalleled employee perks with Swish's E-commerce, your gateway to exclusive best-in-class savings. Elevate the shopping experience for your team as they unlock a curated selection of products and services at unbeatable prices. At Swish, we redefine employee benefits & savings`,
            backgroundColor: 'linear-gradient(96.61deg, #42275A, #734B6D)',
            image: "/mobile/banner_ecom.png"
        },
        {
            id: 3,
            title: "Auto Leasing ",
            subTitle: "Now lease with ease & avail tax benefits",
            text: `At Swish, auto leasing is a breeze – experience the ease of securing dream vehicle for your employees effortlessly. Navigate the road to convenience as we streamline the leasing process, making it as simple as a turn of the key. Enjoy seamless access to tax benefits to make your journey financially rewarding.`,
            backgroundColor: 'linear-gradient(96.61deg, #0F2027, #203A43, #2C5364)',
            image: "/mobile/banner_auto_leasing.png"
        },
        {
            id: 4,
            title: "Voyage Vista: Travel the World with Swish",
            subTitle: "Zero Convenience Fee on all Travel Bookings!",
            text: `Embark on a journey with Swish Travel, where wanderlust meets unparalleled convenience. Experience the joy of booking your flights, bus and hotels with zero convenience fees. With Swish unlock exclusive savings on every booking, no questions asked!`,
            backgroundColor: 'linear-gradient(96.61deg, #CC2B5E, #753A88)',
            image: "/mobile/banner_travel.png"
        },
        {
            id: 5,
            title: "One-of-a-Kind Affordability Suite!",
            subTitle: "Salaried Deductible EMIs on Swish E-commerce platform",
            text: `Redefining affordability through salaried deductible EMIs, we empower employees to manage their finances with ease. Enjoy the convenience of auto deductions on everything your employees you want to buy.`,
            backgroundColor: 'linear-gradient(96.61deg, #9D50BB, #6E48AA)',
            image: "/mobile/banner_finan.png"
        },
        {
            id: 6,
            title: "Full stack TAX SAVINGS",
            subTitle: "Offer a host of tax savings benefits to your employees",
            text: `Experience the power of full-stack tax savings with Swish, where you can kick-start flexi-benefits for all your employees on Day 0. Swish provides fully-automated tool to manage end-to-end lifecycle of tax free flexi-benefits from issuance, to reimbursement to proofs without any overhead costs.`,
            backgroundColor: 'linear-gradient(96.61deg, #3C3B3F, #605C3C)',
            image: "/mobile/banner_tax_saving.png"
        },

    ],
    "testimonials": [
        {

            "id": 3,
            "name": "Jaishree Sharma",
            "designation": "​Sr Director, Jubilant Group ",
            "testimonial": '“Employees now expect us to do a lot more than just offering a stable job and monthly salary. We want to do more to really ‘show that we care’ but administrative burden of introducing anything new becomes the bottleneck. We therefore are highly selective in what to offer to our employees.”',
            personImage: "/testimonial_jaishree.png",
            "companyLogo": "/jubilant_bhartia.png",

        },
        {
            "id": 1,
            testimonial: '“I get 3-5 emails everyday with new solutions and we don’t end up adopting any of them. Primary concern is that adoption of these piecemeal solutions is negligible as employees never use them & end up forgetting about them.”',
            name: 'Prateek Singh',
            designation: 'CHRO, Integrace Health',
            companyLogo: '/integrace.png',
            personImage: "/testimonial_prateek.png"
        },
        {
            "id": 2,
            testimonial: '“I prefer low/zero cost solutions as my benefits budget is already overshooting and is unfortunately the first to get slashed in a bad economy.”',
            name: 'Akshay Kejriwal',
            designation: 'CHRO, OmniActive Health',
            companyLogo: '/omni_active.png',
            personImage: "/testimonial_akshay.png"
        },
        {
            "id": 4,
            testimonial: '“Coke US employees can avail something similar [to Swish] but we haven’t yet come across a credible partner for our employees in India. Excited for Swish to bridge this gap!”',
            name: 'Niharika Gaur​ ',
            designation: 'Head of Total Rewards, Coca-Cola Co',
            companyLogo: '/coca_cola.png',
            personImage: "/testimonial_niharika.png"
        }
    ],
}

export default data;