import React from "react"

const MetricBlog = ({metricdata}) => {
    return (
        <div className="rectangle-parent5">
            <div className="group-child3">
            <div className="benefits">
                <div className="inner">
                    <div className="frame-div">
                        {
                            metricdata.length > 0 && metricdata.map((data) => {
                                return (
                                    <div key={data.id} className={data.className1}>
                                        <b className="e-retention">{data.title}</b>
                                        <div className="propel-your-company">
                                            {data.label}
                                        </div>
                                        <div className="div1">{data.value}</div>
                                        <img className="arrow-icon" alt="" src="/arrow-1.svg"/>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default MetricBlog